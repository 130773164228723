// @ts-nocheck
import moment
  from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { DateRange, extendMoment } from 'moment-range';

const Moment = extendMoment(moment);

const TIME_SERIES: {[index: string]:any} = {
  hour: (range: DateRange) => Array.from(range.by('hour'))
    .map((d: moment) => d.format('YYYY-MM-DDTHH:00:00.000')),
  day: (range: DateRange) => Array.from(range.by('day'))
    .map((d: moment) => d.format('YYYY-MM-DDT00:00:00.000')),
  week: (range: DateRange) => Array.from(range.snapTo('isoweek').by('weeks'))
    .map((d: moment) => d.startOf('isoweek').format('YYYY-MM-DDT00:00:00.000')),
  month: (range: DateRange) => Array.from(range.snapTo('month').by('month'))
    .map((d: moment) => d.format('YYYY-MM-01T00:00:00.000')),
  quarter: (range: DateRange) => Array.from(range.snapTo('quarter').by('quarter'))
    .map((d: moment) => d.format('YYYY-MM-01T00:00:00.000')),
  year: (range: DateRange) => Array.from(range.snapTo('year').by('year'))
    .map((d: moment) => d.format('YYYY-01-01T00:00:00.000')),
};

export default (
  start: number,
  stop: number,
  step: string,
  timezone: string,
) => {
  const startInTz = moment.tz(start, timezone);
  const endWithTz = moment.tz(stop, timezone);
  const nowInTZ = moment().tz(timezone);
  const range: moment = [
    startInTz.isAfter(nowInTZ) ? nowInTZ : startInTz,
    endWithTz.isAfter(nowInTZ) ? nowInTZ : endWithTz,
  ];
  return TIME_SERIES[step](Moment.range(range));
};
