import { gql } from '@apollo/client';
import communityExpertFragment
  from '../../../graphql/fragments/communityExpertFragment';

export default gql`
  ${communityExpertFragment}
  query GetPersistedExpertProfile($partnerId: ID!, $expertId: ID!) {
    community(id: $partnerId) {
      id
      expert(expertId: $expertId) {
        ...CommunityExpertFragment
        bestFeedbackComments {
          comment
        }
        conversationLevel
        mentions {
          id
          summary
          url
          headline
        }
      }
    }
  }
`;
