import React from 'react';
import {
  List,
  Typography,
  Avatar as AntAvatar,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Avatar from './Avatar';
import ExpertNotificationsTags from './ExpertNotificationsTags';
import ExpertName from './ExpertName';

const ProfilerWrapper = styled('span')`
  button {
    visibility: hidden;
    margin-left: 4px;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
`;

const TextWrap = styled.span`
  word-wrap: anywhere;
`;

interface ExpertAvatarProps {
  expert?: {
    id: string;
    uid?: number;
    email: string;
    isEmailVerified: boolean;
    name: string;
    photoUrl: string;
  };
  hideEmail?: boolean;
  showTags?: boolean;
  showEmailVerified?: boolean;
  description?: React.ReactNode;
  extraTitle?: React.ReactNode;
  tooltipTitle?: string;
  endTitle?: React.ReactNode;
  showAvatarOnly?: boolean;
  style?: React.CSSProperties;
  size?: number;
}

const ExpertAvatar = function ({
  expert = undefined,
  hideEmail = false,
  description = null,
  showTags = false,
  showEmailVerified = false,
  extraTitle = null,
  tooltipTitle = '',
  showAvatarOnly = false,
  style = {},
  endTitle = null,
  size = undefined,
}: ExpertAvatarProps) {
  const isExternal = expert?.id.length !== 28;
  const descriptionNode = description || (
    !hideEmail && (<TextWrap>{expert?.email}</TextWrap>)
  );

  const title = description
    ? (
      <>
        {!expert ? '<not available>' : null}
        <ExpertName expert={expert} isExternal={isExternal} />
        <Typography.Text type="secondary">
          {' '}
          {!hideEmail ? expert?.email : ''}
          {extraTitle ? <>{' ('}{extraTitle}{')'}</> : ''}
        </Typography.Text>
      </>
    )
    : <ExpertName expert={expert} isExternal={isExternal} />;

  if (showAvatarOnly) {
    return (
      <Tooltip title={tooltipTitle} key={expert?.uid || expert?.id || 0}>
        <div style={style}>
          {!expert
            ? <AntAvatar size={size} icon={<QuestionOutlined />} />
            : (
              <Link to={`/experts/${expert.id}`}>
                <TextWrap>
                  <Avatar
                    src={expert.photoUrl}
                    text={expert.name}
                    key={expert.id}
                    size={size}
                  />
                </TextWrap>
              </Link>
            )}
        </div>
      </Tooltip>
    );
  }
  return ([
    (<List.Item.Meta
      key={expert?.uid || expert?.id || 0}
      avatar={(
        !expert
          ? <AntAvatar size={size} icon={<QuestionOutlined />} />
          : <Avatar src={expert.photoUrl} size={size} text={expert.name} />
      )}
      title={(
        expert && !isExternal
          ? (
            <ProfilerWrapper>
              <Link to={`/experts/${expert.id}`}>
                <TextWrap>{title}</TextWrap>
              </Link>
              <span style={{ paddingLeft: 8 }}>
                {endTitle}
              </span>
            </ProfilerWrapper>
          )
          : (<TextWrap>{title}</TextWrap>)
      )}
      description={descriptionNode}
    />
    ),
    (showTags && expert && (
      <div key={`${expert.uid || expert.id}-tags`}>
        <ExpertNotificationsTags
          expert={expert}
          showEmailVerified={showEmailVerified}
        />
      </div>
    )),
  ]);
};

export default ExpertAvatar;
