import React from 'react';
import { DEFAULT_AVATAR_PHOTO } from '@guuru/constants-common';
import { str2hsl } from '@guuru/theme-common';
import SizedAvatar from './SizedAvatar';

import type { SizedAvatarProps } from './SizedAvatar';

const handleImgLoadError = (e?: React.ChangeEvent<HTMLImageElement>) => {
  if (e === undefined) {
    return false;
  }
  e.target.onerror = null;
  e.target.src = DEFAULT_AVATAR_PHOTO;
  return false;
};

interface AvatarProps extends SizedAvatarProps {
  text?: string;
}

const Avatar = function ({
  src = undefined,
  className = '',
  style = {},
  shape = 'circle',
  size = 'default',
  text = undefined,
}: AvatarProps) {
  const url = src || DEFAULT_AVATAR_PHOTO;
  const initials = text?.split(' ').map((n) => n[0]).join('');
  return (
    <SizedAvatar
      style={{
        ...style,
        backgroundColor: str2hsl(text),
      }}
      shape={shape}
      size={size}
      $size={className}
      src={url === DEFAULT_AVATAR_PHOTO ? undefined : url}
      onError={handleImgLoadError}
    >
      {initials}
    </SizedAvatar>
  );
};

export default Avatar;
