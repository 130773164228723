import React from 'react';
import {
  FacebookOutlined,
  FileTextOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { colors } from '@guuru/constants-common';
import { SalesforceOutlined } from '@guuru/react-icon';

interface Channel {
  name: string;
  value: string,
  icon: React.FC
  chartColor: string,
}

const channels: Record<string, Channel> = {
  chat: {
    name: 'Chat',
    value: 'chat',
    icon: MessageOutlined,
    chartColor: colors.blue,
  },
  form: {
    name: 'Form',
    value: 'form',
    icon: FileTextOutlined,
    chartColor: colors.yellow,
  },
  facebook: {
    name: 'Facebook',
    value: 'facebook',
    icon: FacebookOutlined,
    chartColor: colors.red,
  },
  salesforce: {
    name: 'Salesforce',
    value: 'salesforce',
    icon: SalesforceOutlined,
    chartColor: colors.orange,
  },
};

const channelsChartConfig = Object.keys(channels).reduce((res, key) => {
  res[key] = { label: channels[key].name };
  return res;
}, {} as Record<string, { label: string }>);

const channelsFilters = Object.values(channels);

export { channels, channelsChartConfig, channelsFilters };
