const rolesDescriptions = {
  admin: 'Unrestricted access to the account, its data, and its settings.',
  member: 'Members can only view and act on the data account.',
};

const membersRoles = {
  admin: 'Administrator',
  member: 'Member',
  superGuuru: 'Super Guuru',
};

const globalRoles = {
  salesManager: 'Sales Manager',
};

const noPartnerRoles = {
  tagger: 'Tagger',
};

const allRoles = {
  financeManager: 'Finance Manager',
  communityManager: 'Community Manager',
  productManager: 'Product Manager',
  engineer: 'Engineer',
  salesGlobalAll: 'Global Sales',
  superAdmin: 'Super Admin',
};

const permissionsRoles = {
  permissionsManager: 'Permissions Manager',
};

const baseRoles = {
  guuru: 'Guuru',
  agent: 'Agent',
  token: 'token',
  anonymous: 'anonymous',
};

const possibleRoles = {
  ...baseRoles,
  ...membersRoles,
  ...globalRoles,
  ...allRoles,
  ...permissionsRoles,
  ...noPartnerRoles,
};

export {
  membersRoles,
  possibleRoles,
  allRoles,
  noPartnerRoles,
  globalRoles,
  permissionsRoles,
  baseRoles,
  rolesDescriptions,
};
