import { locales } from '@guuru/constants-common';
import isString from 'lodash/isString';
import localStorageHelper from './localStorageHelper';

const CONFIG_SYSTEM_LOCALE = 'SYSTEM_LOCALE';

type Message = string | {[key: string]: string | undefined};

const HELPER = {

  isValidLanguage: (language: string, configuredLanguages = locales) => {
    let validLanguages = configuredLanguages;
    if (validLanguages === null) {
      validLanguages = locales;
    }
    return validLanguages.includes(language);
  },

  getValidSystemLocale: ({
    defaultLanguage,
    configuredLanguages,
    locale = '',
  }: {
    defaultLanguage: string,
    configuredLanguages: string[],
    locale: string,
  }) => {
    const language = locale.toLowerCase();
    if (HELPER.isValidLanguage(language, configuredLanguages)) {
      return language;
    }
    if (HELPER.isValidLanguage(defaultLanguage, configuredLanguages)) {
      return defaultLanguage;
    }
    if (configuredLanguages?.length) {
      return configuredLanguages[0];
    }
    return 'en';
  },

  storeSystemLocale: (locale: string) => {
    localStorageHelper.setConfig(CONFIG_SYSTEM_LOCALE, locale);
  },

  getSystemLocale: () => localStorageHelper.getConfig(CONFIG_SYSTEM_LOCALE),

  localize: (m: Message, locale = '', defaultLocale = 'en') => {
    if (isString(m)) {
      return m;
    }
    if (!Object.keys(m || {}).length) {
      return '';
    }
    const someLocale = Object.keys(m).find((l) => m[l]);
    return (locale ? m[locale] : undefined)
      || m[defaultLocale]
      || m.de
      || m.fr
      || m.it
      || m.nl
      || (someLocale ? m[someLocale] : '');
  },

};

export default HELPER;
