import {
  CalendarOutlined,
  CustomerServiceOutlined,
  LinkOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  ReadOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';

const QuickActionsIcons = [
  { icon: LinkOutlined, value: 'link', text: 'Link' },
  { icon: PhoneOutlined, value: 'phone', text: 'Phone' },
  { icon: ReadOutlined, value: 'read', text: 'Read' },
  { icon: MessageOutlined, value: 'message', text: 'Message' },
  { icon: MailOutlined, value: 'mail', text: 'Mail' },
  { icon: CalendarOutlined, value: 'calendar', text: 'Calendar' },
  { icon: ShoppingOutlined, value: 'shopping', text: 'Shopping' },
  { icon: CustomerServiceOutlined, value: 'customer-service', text: 'Customer Service' },
];

// eslint-disable-next-line import/prefer-default-export
export { QuickActionsIcons };
