import { useEffect, useState } from 'react';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { useLiveQuery } from '@guuru/react-hooks';
import { storage, botMessage } from '@guuru/chat-web';
import { MESSAGES } from '@guuru/chat-common';
import GET_CHAT_IS_TYPING from './queries/getChatIsTyping';
import EXPERT_IS_TYPING from './subscriptions/expertIsTyping';
import USER_IS_TYPING from './subscriptions/userIsTyping';

const { isBotTypingVar } = storage;

const createIsTypingMessage = (author) => ({
  author,
  metadata: {},
  text: '',
  i18n: '',
  id: uuidv4(),
  type: MESSAGES.typing,
  createdAt: moment().valueOf(),
});

const {
  node: botTypingMessage,
} = botMessage(MESSAGES.typing, '', MESSAGES.typing);

export default ({ chatId, isUser, skip }) => {
  const [message, setMessage] = useState();
  const isBotTyping = useReactiveVar(isBotTypingVar);

  const {
    data: {
      chat: {
        user = {},
        expert = {},
        expertIsTyping,
        userIsTyping,
      } = {},
    } = {},
  } = useLiveQuery(
    GET_CHAT_IS_TYPING,
    isUser ? EXPERT_IS_TYPING : USER_IS_TYPING,
    {
      variables: { chatId },
      skip: !chatId || skip,
    },
  );

  useEffect(() => {
    const uid = isUser ? expert?.id : user?.id;
    if (!uid) return;

    const author = isUser
      ? { ...expert, __typename: 'ExpertAuthor' }
      : { ...user, __typename: 'UserAuthor' };

    const isTypingMessage = createIsTypingMessage(author);
    setMessage(isTypingMessage);
  }, [expert, isUser, user]);

  if (isBotTyping) {
    return botTypingMessage;
  }

  if (!chatId) {
    return undefined;
  }

  if (isUser && expertIsTyping) {
    return message;
  }

  if (!isUser && userIsTyping) {
    return message;
  }

  return undefined;
};
