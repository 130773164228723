import { fromCents } from './amount';
import { currencies } from './currencies';

const supportsLocaleString = () => (
  !!(typeof Intl === 'object' && typeof Intl.NumberFormat === 'function')
);

const printMoney = (
  value: number,
  currency: string,
  { isCents = false } = {},
) => {
  const amount = isCents ? fromCents(value) : value;

  if (supportsLocaleString()) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: currencies[currency]?.name || currency,
    });
  }

  const symbol = currencies[currency]?.symbol || currency;

  // Note that NumberFormat uses a special kind of white space between the
  // currency and the amount. We do the same here, after ${symbol}.
  // Although it looks like a normal whitespace, it is not.
  //
  // eslint-disable-next-line no-irregular-whitespace
  return (symbol.length > 1 ? `${symbol} ` : symbol) + amount.toFixed(2);
};

interface Balance {
  amount: number;
  currency: string;
}

const printBalances = (balances?: Balance[]) => {
  if (!balances?.length) {
    return '—';
  }

  return balances
    .map(({ amount, currency }) => printMoney(amount, currency, {
      isCents: true,
    }))
    .join(', ');
};

export { printMoney, printBalances };
