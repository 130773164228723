import { useEffect } from 'react';

interface CloseEvent extends Omit<Event, 'returnValue'> {
  returnValue?: string;
}

const confirmClose = (ev: CloseEvent) => {
  ev.preventDefault();
  // eslint-disable-next-line no-return-assign,no-param-reassign
  return ev.returnValue = 'You have unsaved changes. Are you sure you want to close?';
};

const addListener = () => {
  window.addEventListener('beforeunload', confirmClose);
};

const removeListener = () => {
  window.removeEventListener('beforeunload', confirmClose);
};

const useBeforeunload = function (visible: boolean) {
  useEffect(() => {
    if (!visible) {
      removeListener();
    }
    return removeListener;
  }, [visible]);
  return {
    addBeforeUnloadListener: addListener,
    removeBeforeUnloadListener: removeListener,
  };
};

export default useBeforeunload;
