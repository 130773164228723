import { cancel, put } from 'redux-saga/effects';
import { getCurrentChatId, types } from '@guuru/chat-web';
import events from '@guuru/events-web';
import { ChatAPI } from '@guuru/api-web';
import { MESSAGES } from '@guuru/chat-common';
import { delay as sleep } from '@guuru/helpers-common';
import createChat from '../chatActions/createChat';
import handleChatStatusChange from '../chatActions/handleChatStatusChange';
import hotTransferChat from '../chatActions/hotTransferChat';

const thankForFillingForm = 'bot:::thankForFillingForm';

const addBotThanksMessage = async () => {
  const customerName = ChatAPI.storeRetrieveName();
  const thanksMessage = await (
    sleep(ChatAPI.getBotDelayMessageDelay())
      .then(() => {
        const message = ChatAPI.messages.createBotMessage(
          new Date().getTime(),
          thankForFillingForm,
          MESSAGES.message,
          { customerName },
          true,
          false,
        );
        ChatAPI.pushPendingMessages(message);
        return message;
      })
  );
  await put({
    type: types.CHAT_NEW_TEXT_SUCCESS,
    message: thanksMessage,
  });
};

export default {
  validate() {
    return true;
  },
  * render(partnerId) {
    events.connectingWithExpert();

    yield addBotThanksMessage();

    // check if we have already a chat
    // if no then just create a new one
    // if yes then we do an hotTransfer and we need to send the messages
    // that are not yet on the database :(
    const currentChatId = getCurrentChatId();

    if (!currentChatId) {
      const {
        chatId,
        chatControlToken,
        updatedChat,
      } = yield createChat(partnerId);

      if (!chatId) yield cancel();

      yield put({
        type: types.CHAT_READY,
        chatId,
        chatControlToken,
        messages: updatedChat?.messages?.edges,
      });
      handleChatStatusChange({ chatId, ...updatedChat });
      return { goToNextStep: false };
    }

    yield hotTransferChat(partnerId, currentChatId);

    return { goToNextStep: false };
  },
  processResponse() {},
};
