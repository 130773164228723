import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import events from '@guuru/events-web';
import { ChatMessageBar, InputSection, Widget } from '@guuru/react-chatwidget';
import { ChatAPI } from '@guuru/api-web';
import {
  createMessageForPresentation,
  flow as flowConstants,
  useChatSettings,
  useLocalChat,
} from '@guuru/chat-web';
import LoadingView from '@guuru/react-loading';
import * as chatAction from '../../actions/chatActions';
import useChatWrapper from '../../hooks/useChatWrapper';
import ChatPoweredBy from './ChatPoweredBy';
import ChatConnectionRetryCta from './ChatConnectionRetryCta';
import ChatQuickQuestions from './ChatQuickQuestions';
import ChatAlert from '../../components/ChatAlert';
import useIsMobile from '../../hooks/useIsMobile';
import ExpertProfileSheet from '../../components/ExpertProfileSheet';

const {
  STEP_QUESTION,
  STEP_EMAIL,
  STEP_NAME,
  STEP_RATE,
  STEP_CHAT,
  STEP_FINISHED,
  STEP_CONNECTING_TO_EXPERT,
  STEP_RATE_BAD,
  STEP_AUTOMATION,
  INPUT_TYPE_NONE,
  INPUT_TYPE_RESTART,
} = flowConstants;

const Chat = function () {
  const didMount = useRef(false);
  const { isMobile } = useIsMobile();

  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chatReducer);
  const { partnerId, chatControlToken, chatId: chatIdParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = ChatAPI.storeRetrieveParams();
  const isCommunity = params.channel === 'community';
  const channel = isCommunity ? params.channel : 'chat';

  const {
    loading: isLoadingSettings,
    data: {
      chatSettings: {
        chatWidgetShowBotAvatar,
        chatWidgetCustomBotAvatar,
        chatWidgetCustomBotAvatarPhotoUrl,
        chatWidgetShowPoweredBy,
      } = {},
    } = {},
  } = useChatSettings();

  useEffect(() => {
    if (!didMount.current) {
      dispatch(
        chatAction.chatInitAction(
          partnerId,
          chatIdParam,
          chatControlToken,
          navigate,
          location,
          { channel },
        ),
      );

      didMount.current = true;
    }
  }, [
    dispatch,
    navigate,
    location,
    chatControlToken,
    chatIdParam,
    partnerId,
    channel,
  ]);

  const handleConnectionError = () => {
    dispatch(chatAction.chatConnectionError(partnerId));
  };

  const { currentChatId: chatId, hasCurrentChat, ...flow } = useLocalChat();
  useChatWrapper();

  const reduxMessages = useMemo(() => chat.messages?.map(
    // if it has typename we know that this msg comes from gql
    // so no need to convert it
    // eslint-disable-next-line no-underscore-dangle
    (m) => (m.__typename ? m : createMessageForPresentation(m)),
  ), [chat]);

  const displayPoweredBy = useMemo(() => {
    const stepsWherePoweredByIsVisible = [
      STEP_QUESTION,
      STEP_EMAIL,
      STEP_NAME,
    ];

    return chatWidgetShowPoweredBy
      && stepsWherePoweredByIsVisible.includes(flow.step);
  }, [chatWidgetShowPoweredBy, flow.step]);

  const handleAskNewQuestion = useCallback(() => {
    events.userReloadChat();
    dispatch(
      chatAction.chatNewQuestion(
        partnerId,
        navigate,
        location,
        { channel },
      ),
    );
  }, [
    dispatch, partnerId, navigate, location, channel,
  ]);

  const renderChatMessageBar = useCallback(({
    onAttachmentSubmit,
    onMessageSubmit,
  }) => {
    const isHidden = ({ inputType, step, isRunningStep }) => {
      const isInputNoneInStep = inputType === INPUT_TYPE_NONE;
      const isHiddenWhileRunningStep = (
        isRunningStep && [STEP_AUTOMATION, STEP_QUESTION].includes(step)
      );
      return (isInputNoneInStep || isHiddenWhileRunningStep);
    };

    if (isHidden(flow)) {
      return null;
    }

    const handleSubmit = async (eventLabel, text, type) => {
      if (text.trim().length > 0) {
        onMessageSubmit();
        events.submit(eventLabel);
        dispatch(
          chatAction.chatNewText(
            partnerId,
            chatId,
            {
              text,
              step: flow.step,
              inputType: flow.inputType,
              textType: type || 'text',
              skipAutomation: params.priority === 'bot',
            },
          ),
        );
      }
    };

    return (
      <InputSection isUser>
        <ChatMessageBar
          isUser
          noFocus={params.noFocus === 'true' || isMobile}
          chatId={chatId}
          partnerId={partnerId}
          inputType={flow.inputType}
          onSubmit={handleSubmit}
          onAttachmentSubmit={onAttachmentSubmit}
          isClosed={flow.step === STEP_RATE}
          isReviewingRate={flow.step === STEP_RATE_BAD}
          isFinished={flow.inputType === INPUT_TYPE_RESTART
            && !flow.isRunningStep}
          onAskNewQuestion={handleAskNewQuestion}
        />
      </InputSection>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flow,
    chatId,
    partnerId,
    params.priority,
    params.noFocus,
    dispatch,
    handleAskNewQuestion,
  ]);

  const getMods = () => {
    let mods = '';

    if (displayPoweredBy) {
      mods = `${mods} mod--powered-by`;
    }

    switch (flow.step) {
      case STEP_CHAT:
        mods = `${mods} mod--chat`;
        break;
      case STEP_RATE:
      case STEP_RATE_BAD:
        mods = `${mods} mod--rate`;
        break;
      case STEP_CONNECTING_TO_EXPERT:
        mods = `${mods} mod--connecting`;
        break;
      case STEP_FINISHED:
        mods = `${mods} mod--finished`;
        break;
      default:
        break;
    }

    return mods;
  };

  if (isLoadingSettings || chat.loading || !didMount.current) {
    return <LoadingView />;
  }

  return (
    <Widget
      isUser
      chatId={chatId}
      reduxMessages={reduxMessages}
      flow={flow}
      showBotAvatar={chatWidgetShowBotAvatar}
      customBotAvatar={chatWidgetCustomBotAvatar ? chatWidgetCustomBotAvatarPhotoUrl : ''}
      showTermsAndConditions
      baseMods={getMods()}
      renderChatMessageBar={renderChatMessageBar}
      dispatch={dispatch}
      isCommunity={isCommunity}
      alert={<ChatAlert />}
    >
      <ChatQuickQuestions />
      <ChatPoweredBy
        isUser
        visible={displayPoweredBy}
        partnerId={partnerId}
      />
      <ChatConnectionRetryCta
        visible={flow.isConnectionError}
        action={handleConnectionError}
      />
      <ExpertProfileSheet chatId={chatId} />
    </Widget>
  );
};

export default Chat;
