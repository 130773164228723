import iban from 'iban';

const validCountries = {
  ...iban.countries,
  US: { countryKey: 'US' },
  CA: { countryKey: 'CA' },
};

// eslint-disable-next-line import/prefer-default-export
export { validCountries };
