import { generate, grey } from '@ant-design/colors';

export default (primaryColor) => {
  const primaryVariants = generate(primaryColor);

  return {
    primaryColor,
    primaryVariants,
    secondaryColor: '#ffffff',
    secondaryVariants: primaryVariants,
    neutralVariants: grey,
    backgroundColor: primaryColor,
    surfaceColor: '#ffffff',
    errorColor: '#ff4d4f',
    successColor: '#52c41a',
    onPrimaryColor: '#ffffff',
    onSecondaryColor: grey[11],
    onLightColor: grey[11],
    onBackgroundColor: '#ffffff',
    onSurfaceColor: grey[11],
    onErrorColor: '#ffffff',
    smallRadius: '5px',
    mediumRadius: '15px',
    shadow: '0 2px 6px 0 hsla(0, 0%, 0%, 0.2)',
    disabledBackgroundColor: '#f5f5f5',
    disabledBorderColor: '#d9d9d9',
    disabledTextColor: 'rgba(0, 0, 0, 0.25)',
    guuruColor: '#00B0FF',
  };
};
