import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Flex } from 'antd';
import { useChatSettings } from '@guuru/chat-web';
import { BOTS } from '@guuru/expert-common';
import { Spacer } from './styles';
import Headline from './Headline';

const Heading = function ({ chat = {} }) {
  const {
    data: {
      chatSettings: {
        chatWidgetShowProfiles,
        chatWidgetProfilesPictures,
        chatWidgetProfilesCount,
      },
    } = {},
  } = useChatSettings();

  const expertName = chat?.expert?.name;
  const isBot = chat?.expert?.id === BOTS.bot.externalId;

  const heroes = useMemo(
    () => {
      const sorted = [...chatWidgetProfilesPictures]
        .sort(() => Math.random() - 0.5);
      return chatWidgetProfilesCount > chatWidgetProfilesPictures.length
        ? [...sorted, ...Array(chatWidgetProfilesCount - sorted.length).keys()]
        : [...sorted];
    },
    [chatWidgetProfilesCount, chatWidgetProfilesPictures],
  );

  return (
    <Flex>
      <Spacer direction="vertical">
        <Headline chat={chat} />
        {(!expertName || isBot) && chatWidgetShowProfiles && (
          <Avatar.Group
            max={{
              count: Math.min(chatWidgetProfilesPictures.length, 6),
              popover: { trigger: 'none' },
              style: chatWidgetProfilesPictures.length < 6
                ? { display: 'none' }
                : { boxShadow: 'none', opacity: 0.9 },
            }}
          >
            {heroes.map((url) => <Avatar src={url} size={38} key={url} />)}
          </Avatar.Group>
        )}
      </Spacer>
    </Flex>
  );
};

Heading.propTypes = {
  chat: PropTypes.shape({
    expert: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    isAssignedToAgent: PropTypes.bool,
  }),
};

export default Heading;
