import { utc as moment } from 'moment';

const HELPER = {
  prettyDate: (dateText: string, granularity: string) => {
    if (!granularity) {
      return moment(dateText).format('MMM DD, YYYY');
    }
    switch (granularity) {
      case 'hour':
        return moment(dateText).format('MMM DD, YYYY[\n] H:mm');
      case 'week':
        return `Week ${moment(dateText).isoWeek()}`;
      case 'month':
        return moment(dateText).format('MMM YYYY');
      case 'quarter':
        return `Quarter ${moment(dateText).quarters()}`;
      case 'year':
        return moment(dateText).format('YYYY');
      default:
        return moment(dateText).format('MMM DD');
    }
  },
  dateFormat: (granularity: string) => {
    switch (granularity) {
      case 'month':
        return 'mmm yyyy';
      case 'year':
        return 'yyyy';
      default:
        return 'mmm d';
    }
  },
  showAsDate: (granularity: string) => (
    ['day', 'month', 'year'].includes(granularity)
  ),
};

export default HELPER;
