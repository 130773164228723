import React from 'react';
import { Card, Skeleton } from 'antd';
import Cover from '../Cover';
import { Meta } from './style';

interface HorizontalLayoutProps {
  loading: boolean;
  title?: string;
  name?: string;
  avatar?: string;
  description?: string;
}

const HorizontalLayout = function ({
  loading,
  avatar = undefined,
  title = undefined,
  name = undefined,
  description = undefined,
}: HorizontalLayoutProps) {
  return (
    <Card
      hoverable
      styles={{ body: { padding: '0 15px 0 0' } }}
      style={{ marginBottom: 0 }}
    >
      <Skeleton
        active
        avatar={{ size: 150, shape: 'square' }}
        loading={loading}
        paragraph={{ rows: 3, width: [400, 240, 100] }}
        title={false}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Meta
          style={{ alignItems: 'center' }}
          avatar={(
            <Cover
              src={avatar}
              text={name || title}
              style={{ borderRadius: '5px 0 0 5px' }}
              size={120}
            />
          )}
          title={title}
          description={description}
        />
      </Skeleton>
    </Card>
  );
};

export default HorizontalLayout;
