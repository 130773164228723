import React from 'react';
import {
  Card,
  Flex,
  Skeleton,
  Typography,
} from 'antd';
import Cover from '../Cover';

const { Text, Paragraph } = Typography;

interface VerticalLayoutProps {
  loading: boolean;
  title: string;
  description: string;
  name?: string;
  subTitle?: string;
  avatar?: string;
  footer?: React.ReactNode;
  extraTitle?: React.ReactNode;
  actions?: (string | React.ReactNode)[];
}

const VerticalLayout = function ({
  title,
  loading,
  description,
  avatar = undefined,
  name = undefined,
  subTitle = undefined,
  footer = null,
  extraTitle = null,
  actions = [],
}: VerticalLayoutProps) {
  return (
    <Card
      hoverable
      bordered={false}
      actions={actions}
      cover={loading
        ? undefined
        : (
          <Cover
            src={avatar}
            text={name || title}
          />
        )}
    >
      <Skeleton
        loading={loading}
        active
        title={false}
        avatar={{ size: 100, shape: 'square' }}
        paragraph={{ rows: 3, width: [100, 50, 120] }}
        style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      >
        <Flex vertical gap="middle">
          <Flex justify="space-between" wrap="wrap" align="center">
            <Card.Meta
              title={subTitle ? title : title.split(' ')[0]}
              description={subTitle}
            />
            {extraTitle}
          </Flex>
          <Flex vertical justify="space-between">
            {description && (
            <Paragraph ellipsis={{
              rows: 6,
              expandable: true,
              symbol: 'more',
            }}
            >
              {`“${description}”`}
            </Paragraph>
            )}
            <Text type="secondary">
              {footer}
            </Text>
          </Flex>
        </Flex>
      </Skeleton>
    </Card>
  );
};

export default VerticalLayout;
