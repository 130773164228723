import { gql } from '@apollo/client';

export default gql`
  query GetLeaderboard($partnerId: ID!, $expertId: ID, $fetchExpert: Boolean!) {
    community(id: $partnerId) {
      id
      leaderboard {
        edges {
          node {
            id
            externalId
            name
            photoUrl
            scores {
              profile
              happiness
              style
              score
            }
          }
        }
      }
      expert(expertId: $expertId) @include(if: $fetchExpert) {
        id
        externalId
        name
        photoUrl
        scores {
          profile
          happiness
          style
          score
        }
      }
    }
  }
`;
