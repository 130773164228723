import { delay as sleep } from '@guuru/helpers-common';
import { MESSAGES } from '@guuru/chat-common';
import { ChatAPI } from '@guuru/api-web';

export default (partnerId, automationBehavior) => (
  sleep(ChatAPI.getBotEllipsisMessageDelay())
    .then(() => {
      const messageStyle = ChatAPI.getMessageStyle(partnerId);
      const subscriptionOptionsText = automationBehavior
        ?.subscriptionOptionsText;
      const subscriptionPriority = automationBehavior?.subscriptionPriority;
      const subscriptionTarget = automationBehavior?.subscriptionTarget;
      const subscriptionOptions = automationBehavior?.subscriptionOptions;
      const subscriptionAnswer = automationBehavior?.subscriptionAnswer;
      const message = ChatAPI.messages.createMessageType(
        null,
        false,
        MESSAGES.predictionResult,
        subscriptionOptionsText,
        {
          messageStyle,
          subscriptionTarget,
          subscriptionOptions,
          subscriptionPriority,
          subscriptionAnswer,
        },
      );
      ChatAPI.pushPendingMessages(message);
      return message;
    })
);
