import {
  BulbOutlined,
  FileSearchOutlined,
  SmileOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';

const FeedbackTopic = {
  'Friendly Chat': {
    icon: SmileOutlined,
  },
  'Very Helpful': {
    icon: BulbOutlined,
  },
  Knowledgeable: {
    icon: FileSearchOutlined,
  },
  'Quick and efficient': {
    icon: ThunderboltOutlined,
  },
};

// eslint-disable-next-line import/prefer-default-export
export { FeedbackTopic };
