import React from 'react';
import { ConfigProvider, Grid, Typography } from 'antd';
import styled from 'styled-components';
import { generatePath, useParams, useSearchParams } from 'react-router-dom';
import ExpandableRow from './ExpandableRow';
import Footer from './Footer';
import ExpandIcon from './ExpandIcon';
import LeaderboardTable from './LeaderboardTable';
import LeaderboardSkeleton from './LeaderboardSkeleton';
import useLeaderboard from './hooks/useLeaderboard';
import generateColumns from './Columns';

const { Title } = Typography;

const { useBreakpoint } = Grid;

const LeaderboardTitle = styled(Title)`
  padding-top: 24px;
  margin: 0;
`;

const renderTableFooter = (partnerId, showStyleColumn) => (
  <Footer showStyleColumn={showStyleColumn} partnerId={partnerId} />
);

const Leaderboard = function () {
  const { md } = useBreakpoint();
  const { partnerId } = useParams();
  const [searchParams] = useSearchParams();
  const expertId = searchParams.get('id');
  const isSmallDevice = !md;

  const {
    loading,
    leaderboard,
    showStyleColumn,
  } = useLeaderboard(partnerId, { expertId });

  return (
    <>
      <LeaderboardTitle level={2}>
        Community Top Ten
      </LeaderboardTitle>
      {loading ? (
        <LeaderboardSkeleton />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowExpandedBg: '#FAFAFA',
                headerBg: '#fff',
                headerColor: 'rgb(107 114 128)',
                headerBorderRadius: 6,
                headerSplitColor: '#fff',
                cellPaddingBlock: 12,
                rowHoverBg: '#E6F7FF',
                cellFontSize: 16,
              },
            },
          }}
        >
          <LeaderboardTable
            pagination={false}
            columns={generateColumns(isSmallDevice, showStyleColumn)}
            rowHoverable
            rowClassName={(row) => (
              row?.node?.externalId === expertId ? 'highlight-guuru-row' : ''
            )}
            dataSource={leaderboard}
            footer={() => renderTableFooter(partnerId, showStyleColumn)}
            rowKey={(row) => row?.node?.externalId || row?.id}
            onRow={({ node }) => (node?.externalId && {
              onClick: () => {
                const path = generatePath('./guurus/:id', { id: node?.externalId });
                window.open(path, '_blank');
              },
            })}
            expandable={isSmallDevice && {
              expandedRowRender: ExpandableRow,
              expandIcon: ExpandIcon,
            }}
          />
        </ConfigProvider>
      )}
    </>
  );
};

export default Leaderboard;
