import { firebase } from '@guuru/firebase-web';
import {
  getAdditionalUserInfo,
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

const auth = getAuth(firebase);

export default {
  auth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInAnonymously,
  signOut,
  onAuthStateChanged,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
};
