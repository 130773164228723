import { ChatAPI } from '@guuru/api-web';
import { getChat } from '@guuru/chat-web';
import { BOTS } from '@guuru/expert-common';
import { getCurrentUserId } from '@guuru/auth-web';
import log from '@guuru/logger-web';

export default async (partnerId, chatId) => {
  const userId = await getCurrentUserId();
  if (!userId) {
    log.warn('No user found');
    window.location.reload();
  }
  const pendingMessages = ChatAPI.storeRetrievePendingMessages(partnerId);
  const {
    messages: {
      edges: graphqlMessages = [],
    },
  } = await getChat(chatId);

  for (let index = 0; index < pendingMessages.length; index += 1) {
    const {
      id,
      i18n: localizedMessage,
      hideForUser,
      hideForExpert,
      type,
      timestamp,
      data,
      author,
    } = pendingMessages[index];
    const isUser = author?.uid !== BOTS.bot.externalId;
    // eslint-disable-next-line no-await-in-loop
    await ChatAPI.addMessage(
      id,
      isUser ? userId : BOTS.bot.externalId,
      chatId,
      isUser,
      !isUser,
      localizedMessage,
      hideForUser,
      hideForExpert,
      type,
      data,
      timestamp,
    );
  }
  ChatAPI.storeResetPendingMessages();
  return [...graphqlMessages, ...pendingMessages];
};
