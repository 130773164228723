const DEVICES = {
  mobile: {
    name: 'mobile',
  },
  desktop: {
    name: 'desktop',
    minWidth: 1280,
  },
  tablet: {
    name: 'tablet',
    minWidth: 740,
  },

  getByWidth(width: number) {
    if (width >= DEVICES.desktop.minWidth) {
      return DEVICES.desktop.name;
    }
    if (width >= DEVICES.tablet.minWidth) {
      return DEVICES.tablet.name;
    }
    return DEVICES.mobile.name;
  },
};

// eslint-disable-next-line import/prefer-default-export
export { DEVICES };
