import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import GuuruSvg from './GuuruOutlined.svg';

const GuuruOutlined = function (
  props: Parameters<typeof Icon>,
  ref: React.LegacyRef<HTMLSpanElement>,
) {
  return (
    <Icon
      aria-label="guuru"
      component={GuuruSvg}
      ref={ref}
      {...props}
    />
  );
};

export default React.forwardRef(GuuruOutlined);
