import trim from 'lodash/trim';
import { useChatSettings, useDetectIframe } from '@guuru/chat-web';
import { useMemo, useState } from 'react';
import { ChatAPI } from '@guuru/api-web';

const useTheme = function (partnerId) {
  const inIframe = useDetectIframe();
  const [isFullScreen, setFullScreen] = useState(!inIframe);

  const {
    data: {
      chatSettings: {
        organization,
        chatWidgetPrimaryColor,
        chatWidgetCustomBackground,
        chatWidgetCustomBackgroundPhotoUrl,
        chatWidgetFont,
        smartFormAlignment = 'center',
      } = {},
    } = {},
    loading,
  } = useChatSettings(partnerId);

  const fontFamily = useMemo(() => (
    trim(chatWidgetFont) && chatWidgetFont !== 'system-ui'
      ? 'PartnerFont'
      : undefined
  ), [chatWidgetFont]);

  if (!partnerId || (!chatWidgetPrimaryColor && !organization) || loading) {
    return { loading };
  }

  const chatLayout = !window.location.pathname.includes('/form');
  let chatAlignment = 'center';
  if (!chatLayout) {
    chatAlignment = (isFullScreen ? 'center' : smartFormAlignment);
  }
  return {
    loading,
    fontFamily,
    chatLayout,
    chatAlignment,
    isFullScreen,
    setFullScreen,
    color: chatWidgetPrimaryColor || organization?.color,
    colorScheme: organization ? 'Classic' : 'Clean',
    backgroundUrl: (
      chatWidgetCustomBackground && chatWidgetCustomBackgroundPhotoUrl
    ),
    header: ChatAPI.storeRetrieveParams().header !== 'false',
  };
};

export default useTheme;
