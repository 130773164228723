import { Sentry } from '@guuru/sentry-web';
import React from 'react';
import ErrorPage from './500';

interface ExtendedErrorBoundaryProps extends Sentry.ErrorBoundaryProps {
  ignoreErrors?: string[];
}

interface GraphQLError {
  extensions?: {
    code?: string;
  };
}

interface ErrorWithGraphQL extends Error {
  graphQLErrors?: GraphQLError[];
}

const ErrorBoundary = function ({
  children,
  ignoreErrors = [],
  fallback = ErrorPage,
}: ExtendedErrorBoundaryProps) {
  const errorHandler = (error: unknown, componentStack?: string) => {
    const graphQLError = error as ErrorWithGraphQL;
    const shouldIgnore = graphQLError.graphQLErrors?.every(
      (err: GraphQLError) => {
        const errorCode = err.extensions?.code;
        return errorCode !== undefined && ignoreErrors.includes(errorCode);
      },
    );

    if (!shouldIgnore) {
      Sentry.captureException(error, { extra: { componentStack } });
    }
  };

  return (
    <Sentry.ErrorBoundary
      onError={errorHandler}
      fallback={fallback}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
