import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { MESSAGES } from '@guuru/chat-common';
import { types } from '@guuru/chat-web';
import events from '@guuru/events-web';
import { delay as sleep } from '@guuru/helpers-common';

const requestName = 'bot:::requestName';

const addBotRequestNameMessage = () => (
  sleep(ChatAPI.getBotEllipsisMessageDelay()).then(() => {
    const message = ChatAPI.messages.createBotMessage(
      new Date().getTime(),
      requestName,
      MESSAGES.message,
      {},
      true,
      false,
    );
    ChatAPI.pushPendingMessages(message);
    return message;
  })
);

const addNameMessage = (partnerId, name) => {
  const message = ChatAPI.messages.createAnonymousTextMessage(
    name,
    true,
    false,
  );
  ChatAPI.storeName(partnerId, name);
  ChatAPI.pushPendingMessages(message);
  return message;
};

export default {
  validate(partnerId) {
    const { priority } = ChatAPI.storeRetrieveParams();
    return !ChatAPI.storeRetrieveName(partnerId) && priority !== 'bot';
  },
  * render() {
    events.requestName();

    const requestNameMessage = yield addBotRequestNameMessage();
    yield put({
      type: types.CHAT_NEW_TEXT_SUCCESS,
      message: requestNameMessage,
    });
  },

  processResponse(partnerId, { text }) {
    const message = addNameMessage(partnerId, text);
    events.nameSubmitted(text);
    return put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
  },
};
