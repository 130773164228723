import { useQuery } from '@apollo/client';
import GET_LEADERBOARD from './queries/getLeaderboard';

const useLeaderboard = (partnerId, { expertId } = {}) => {
  const {
    loading,
    data: {
      community: {
        leaderboard: {
          edges = [],
        } = {},
        expert,
      } = {},
    } = {},
  } = useQuery(GET_LEADERBOARD, {
    variables: {
      partnerId,
      expertId,
      fetchExpert: !!expertId,
    },
  });

  if (loading) {
    return { loading: true };
  }

  const leaderboard = [...edges];

  if (expertId && expert && expert?.scores?.score !== null) {
    const isInLeaderboard = leaderboard.some((
      { node: { externalId } = {} },
    ) => externalId === expertId);

    if (!isInLeaderboard) {
      if (leaderboard.length === 10) {
        leaderboard.push({ id: 'separator' });
      }
      leaderboard.push({
        __typename: 'CommunityExpertEdge',
        node: expert,
      });
    }
  }

  return {
    loading: false,
    leaderboard: leaderboard || [],
    showStyleColumn: leaderboard.some((
      { node: { scores: { style } = {} } = {} } = {},
    ) => style !== null),
  };
};

export default useLeaderboard;
