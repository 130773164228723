import React from 'react';
import { Flex, Skeleton, Space } from 'antd';

const SkeletonRow = function () {
  return (
    <Flex gap={16} justify="space-between" style={{ width: '100%' }} align="center">
      <Skeleton.Avatar active size="large" shape="square" />
      <Skeleton.Button block active size="small" />
      <Space>
        <Skeleton.Button active size="small" />
        <Skeleton.Button active size="small" />
      </Space>
      <Skeleton.Avatar active size="large" />
    </Flex>
  );
};

const LeaderboardSkeleton = function () {
  return (
    <>
      <Skeleton.Button block active size="large" />
      {[...Array(10).keys()].map((key) => (
        <SkeletonRow key={key} />
      ))}
    </>
  );
};

export default LeaderboardSkeleton;
