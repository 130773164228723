import React from 'react';
import { Card, Flex } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  HeartOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import AttributeInfo from './AttributeInfo';

const StyledCard = styled(Card)`
  background-color: rgba(227 229 230 / 50%);
  border: 0;
  box-shadow: 0 1px 2px rgba(0 0 0 / 10%);

  .ant-card-body {
    padding: 16px;
  }
`;

const ExpandableRow = function ({ node }) {
  const {
    scores: {
      style,
      profile,
      happiness,
    } = {},
  } = node;

  return (
    <StyledCard>
      <Flex justify="space-around" gap={12}>
        {style !== null && (
          <AttributeInfo
            icon={HeartOutlined}
            title="Style"
            extra={style}
          />
        )}
        <AttributeInfo
          icon={UserOutlined}
          title="Profile"
          extra={profile}
        />
        <AttributeInfo
          icon={SmileOutlined}
          title="Happiness"
          extra={happiness}
        />
      </Flex>
    </StyledCard>
  );
};

ExpandableRow.propTypes = {
  node: PropTypes.shape({
    scores: PropTypes.shape({
      style: PropTypes.string,
      profile: PropTypes.string.isRequired,
      happiness: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ExpandableRow;
