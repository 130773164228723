import React from 'react';
import { Space, Table } from 'antd';
import {
  DashboardOutlined,
  HeartOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { GuuruOutlined } from '@guuru/react-icon';
import Guuru from './Guuru';
import Score from './Score';
import RowSeparator from '../RowSeparator';

const responsiveColumnTypes = [
  { title: 'Profile', icon: UserOutlined },
  { title: 'Happiness', icon: SmileOutlined },
];

const Style = { title: 'Style', icon: HeartOutlined };

const ROW_SEPARATOR_ELEMENT_INDEX = 10;

const generateColumns = (isSmallDevice, showStyleColumn) => {
  const dynamicColumns = showStyleColumn
    ? [...responsiveColumnTypes, Style]
    : responsiveColumnTypes;

  const getOnCell = (cell, index, column) => {
    let colSpan = 1;
    if (column !== 0) {
      colSpan = index === ROW_SEPARATOR_ELEMENT_INDEX ? 0 : 1;
    } else if (index === ROW_SEPARATOR_ELEMENT_INDEX) {
      colSpan = isSmallDevice ? 2 : 2 + dynamicColumns.length;
    }
    return { colSpan };
  };

  const columns = [
    {
      title: <Space><GuuruOutlined />Guuru</Space>,
      dataIndex: 'expert',
      key: 'name',
      align: 'left',
      width: '60%',
      render: (_, { node }, index) => (index !== ROW_SEPARATOR_ELEMENT_INDEX
        ? <Guuru index={index} expert={node} />
        : <RowSeparator />),
      onCell: (cell, index) => getOnCell(cell, index, 0),
    },
    ...dynamicColumns.map(({ title, icon: Icon }) => ({
      title: (
        <Space>
          <span style={{ fontWeight: 400 }}>
            {title}
          </span>
          <Icon />
        </Space>
      ),
      dataIndex: title.toLowerCase(),
      key: title.toLowerCase(),
      align: 'center',
      width: '10%',
      responsive: ['md'],
      render: (
        _,
        { node: { scores = {} } = {} },
      ) => scores[title.toLowerCase()] || 0,
      onCell: (cell, index) => getOnCell(cell, index),
    })),
    {
      title: <Space>Score<DashboardOutlined /></Space>,
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      render: (_, { node: { scores = {} } = {} }) => (
        <Score score={scores.score || 0} fontSize={16} height={42} />
      ),
      onCell: (cell, index) => getOnCell(cell, index),
    },
  ];

  return isSmallDevice ? [...columns, Table.EXPAND_COLUMN] : columns;
};

export default generateColumns;
