import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const MentionFilters = {
  searchText: {
    key: 'searchText',
  },
  status: {
    key: 'status',
    label: 'Status',
    filters: [
      {
        value: 'approved',
        name: 'Approved',
        icon: CheckCircleOutlined
        ,
      },
      {
        value: 'pending',
        name: 'Pending',
        icon: QuestionCircleOutlined,
      },
      {
        value: 'rejected',
        name: 'Rejected',
        icon: CloseCircleOutlined
        ,
      },
    ],
  },
};

// eslint-disable-next-line import/prefer-default-export
export { MentionFilters };
