import React from 'react';
import { Avatar } from '@guuru/react-expert-avatar';

interface CoverProps {
  src?: string;
  text?: string;
  size?: number;
  style?: React.CSSProperties;
}

const Cover = function ({
  src = undefined,
  size = undefined,
  text = undefined,
  style = {},
}: CoverProps) {
  return (
    <Avatar
      size={size}
      src={src}
      text={text}
      shape="square"
      style={{
        objectFit: 'cover',
        aspectRatio: '1 / 1',
        height: '100%',
        width: '100%',
        border: 'inherit',
        borderRadius: '6px 6px 0 0',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        fontSize: 'clamp(42px, calc(2rem + 2vw + 2vh), 64px)',
        ...style,
      }}
    />
  );
};

export default Cover;
