const translations = {
  recruitingClosedMessage: {
    en: 'All spots in this group are taken. Check back later.',
    de: 'Diese Gruppe ist zur Zeit voll. Schau später nochmal vorbei.',
    fr: 'Ce groupe est actuellement plein. Revenez plus tard.',
    it: 'Questo gruppo è attualmente pieno. Controlla più tardi.',
    dk: 'Alle pletter i denne gruppe er taget. Check tilbage senere.',
    es: 'Todos los lugares en este grupo están ocupados. Vuelva más tarde.',
    pt: 'Todos os lugares deste grupo estão ocupados. Volte mais tarde.',
    jp: 'All spots in this group are taken. Check back later.',
    th: 'All spots in this group are taken. Check back later.',
    ko: 'All spots in this group are taken. Check back later.',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { translations };
