const localesData = {
  en: {
    name: 'English',
    nameTranslate: 'English',
    color: '#00B0FF',
  },
  de: {
    name: 'German',
    nameTranslate: 'Deutsch',
    color: '#ED5565',
  },
  fr: {
    name: 'French',
    nameTranslate: 'Français',
    color: '#f8ac59',
  },
  it: {
    name: 'Italian',
    nameTranslate: 'Italiano',
    color: '#23c6c8',
  },
  es: {
    name: 'Spanish',
    nameTranslate: 'Español',
    color: '#fadb14',
  },
  th: {
    name: 'Thai',
    nameTranslate: 'ไทย',
    color: '#292648',
  },
  ko: {
    name: 'Korean',
    nameTranslate: '한국어',
    color: '#2D589C',
  },
  ja: {
    name: 'Japanese',
    nameTranslate: '日本語',
    color: '#BE0029',
  },
  nl: {
    name: 'Dutch',
    nameTranslate: 'Nederlands',
    color: '#ff0000',
  },
  pl: {
    name: 'Polish',
    nameTranslate: 'Polskie',
    color: '#DC143C',
  },
  da: {
    name: 'Danish',
    nameTranslate: 'Dansk',
    color: '#C60C30',
  },
  no: {
    name: 'Norwegian',
    nameTranslate: 'Norsk',
    color: '#003087',
  },
  sv: {
    name: 'Swedish',
    nameTranslate: 'Svenska',
    color: '#FFCD00',
  },
  fi: {
    name: 'Finnish',
    nameTranslate: 'Suomi',
    color: '#1244c8',
  },
  pt: {
    name: 'Portuguese',
    nameTranslate: 'Português',
    color: '#eb2f96',
  },
  zh: {
    name: 'Chinese',
    nameTranslate: '汉语',
    color: '#00ffff',
  },
  ar: {
    name: 'Arabic',
    nameTranslate: 'العَرَبِيَّة',
    color: '#808080',
  },
  ro: {
    name: 'Romanian',
    nameTranslate: 'Română',
    color: '#330000',
  },
  uk: {
    name: 'Ukrainian',
    nameTranslate: 'Українська',
    color: '#005BBB',
  },
  bg: {
    name: 'Bulgarian',
    nameTranslate: 'Болгарська',
    color: '#eb672f',
  },
  cs: {
    name: 'Czech',
    nameTranslate: 'čeština',
    color: '#d7141a',
  },
  sl: {
    name: 'Slovenian',
    nameTranslate: 'Slovenščina',
    color: '#003DA5',
  },
};

const localesKeys = Object.keys(localesData);

const mobileLocales = [
  'en', 'fr', 'de', 'it',
];

const languageToCountryMapping = (language: string) => {
  const country = language;
  switch (country) {
    case 'en': return 'gb';
    case 'ar': return 'sa';
    case 'zh': return 'cn';
    case 'ko': return 'kr';
    case 'sv': return 'se';
    case 'da': return 'dk';
    case 'ja': return 'jp';
    case 'uk': return 'ua';
    case 'cs': return 'cz';
    case 'sl': return 'si';
    default: return country;
  }
};

const countryCodeToLanguage = () => (
  Object.entries(localesData)
    .reduce((map, [countryCode, { name }]) => (
      { ...map, [countryCode]: name }
    ), {})
);

export {
  countryCodeToLanguage as languageMap,
  languageToCountryMapping,
  localesKeys as locales,
  localesData,
  mobileLocales,
};
