const ChatState = {
  created: 'created',
  assigned: 'assigned',
  open: 'open',
  closed: 'closed',
  rated: 'rated',
  handover: 'handover',
  missed: 'missed',
};

// eslint-disable-next-line import/prefer-default-export
export { ChatState };
