import CHAT_PUBLIC_FIELDS from './chatPublicFields';
import CHAT_MESSAGE_FIELDS from './chatMessageFields';

const fragments = {
  CHAT_PUBLIC_FIELDS,
  CHAT_MESSAGE_FIELDS,
};

export default Object.values(fragments);
export { fragments };
