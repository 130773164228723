import cloneDeep from 'lodash/cloneDeep';

const CONFIG = 'Config';

const memLocalStorage: {[Key: string]: any} = {};

const localStorageHelper = {
  save: (partnerId: string, value: any) => {
    try {
      localStorage[partnerId] = JSON.stringify(value);
    } catch (e) {
      memLocalStorage[partnerId] = value;
    }
  },

  get: (key: string) => {
    try {
      return (
        JSON.parse(localStorage[key] || '{}')
      );
    } catch (e) {
      return cloneDeep(memLocalStorage[key] || {});
    }
  },

  getConfig: (key: string) => {
    const config = localStorageHelper.get(CONFIG);
    return config[key];
  },

  setConfig: (key: string, value: any) => {
    const config = localStorageHelper.get(CONFIG);
    config[key] = value;
    localStorageHelper.save(CONFIG, config);
  },

  setObject: (partnerId: string, key: string, value: any) => {
    const partner = localStorageHelper.getAll(partnerId);
    partner[key] = value;
    localStorageHelper.save(partnerId, partner);
  },

  removeObject: (partnerId: string, key: string) => {
    const partner = localStorageHelper.getAll(partnerId);
    delete partner[key];
    localStorageHelper.save(partnerId, partner);
  },

  getObjectForChat: (partnerId: string, chatId: string, key: string) => {
    const partner = localStorageHelper.getAll(partnerId);
    const chats = partner.chatHistory || [];
    const chat = chats.find((c: { chatId: string}) => c.chatId === chatId);
    return chat && chat[key];
  },

  pushObjectForChat: (partnerId: string, chatId: string, values:any) => {
    const partner = localStorageHelper.getAll(partnerId);
    const chats = partner.chatHistory || [];
    const chat = chats.find((c: { chatId: string}) => c.chatId === chatId);
    if (!chat) {
      chats.push(values);
    } else {
      Object.assign(chat, values);
    }
    partner.chatHistory = chats;
    localStorageHelper.save(partnerId, partner);
  },

  getObject: (partnerId: string, key: string) => (
    localStorageHelper.getAll(partnerId)[key]
  ),

  getAll: (partnerId: string) => localStorageHelper.get(partnerId),

  clear: (partnerId: string) => {
    localStorageHelper.save(partnerId, null);
  },
};

export default localStorageHelper;
