import { gql } from '@guuru/graphql-common/gql';

export default gql(`#graphql
  query GetTranslations($filters: [TranslationFilterType!]) {
    globalTranslations(filters: $filters, first: 25) {
      edges {
        node {
          id
          key
          value
        }
      }
    }
  }
`);
