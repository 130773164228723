import styled from 'styled-components';

interface IconProps {
  $enabled?: boolean;
}

const Icon = styled.div<IconProps>`
  padding-left: 0.5rem;
  font-size: 0.875rem;

  svg {
    fill: ${({ theme, $enabled }) => (
    $enabled ? theme.successColor : theme.errorColor
  )};
  }
`;

export default Icon;
