import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import events from '@guuru/events-web';
import { MESSAGES } from '@guuru/chat-common';
import { types } from '@guuru/chat-web';
import { delay as sleep } from '@guuru/helpers-common';

const requestPartnerSelection = 'bot:::requestPartnerSelection';

const addBotRequestPartnerSelection = (partners, delay = true) => (
  sleep(delay ? ChatAPI.getBotEllipsisMessageDelay() : 0).then(() => {
    const message = ChatAPI.messages.createBotMessage(
      new Date().getTime(),
      requestPartnerSelection,
      MESSAGES.partnerSelection,
      { partners },
      true,
      false,
    );
    return message;
  })
);

const organizationSelection = {
  validate() {
    return true;
  },

  * render(_, { action }) {
    const locale = ChatAPI.storeRetrieveLocale();
    const { organization: { partners } = {} } = (
      yield ChatAPI.getChatSettings(action.partnerId, locale)
    );
    events.requestPartnerSelection(locale, action.partnerId);
    const partnerSelection = yield addBotRequestPartnerSelection(partners);
    yield put({
      type: types.CHAT_NEW_TEXT_SUCCESS,
      message: partnerSelection,
    });
    yield put({ type: types.CHAT_LOADED });
    return { goToNextStep: false };
  },

  processResponse() {},

};

export default organizationSelection;
