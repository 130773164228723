import { Sentry } from '@guuru/sentry-web';
import { errorType } from '@guuru/graphql-common';

import type { ApolloError, ServerError } from '@apollo/client';

const REFRESH_INTERVAL_MS = process.env.REFRESH_INTERVAL_MS || (5 * 60 * 1000);
const userErrorCodes = Object.keys(errorType);

const reloadOnSchemaError = (code: string) => {
  if (code !== 'GRAPHQL_VALIDATION_FAILED') return false;

  const lastCheckAt = Number(window.localStorage.getItem('lastVersionCheckAt'));
  if (lastCheckAt > Date.now() - Number(REFRESH_INTERVAL_MS)) return false;

  window.localStorage.setItem('lastVersionCheckAt', String(Date.now()));
  window.location.reload();
  return true;
};

interface ErrorCallbackOptions extends ApolloError {
  operation: {
    operationName?: string;
    variables?: { [key: string]: any };
  }
}

const errorCallback = ({
  operation = {},
  graphQLErrors,
  networkError,
}: ErrorCallbackOptions) => {
  let isUserError = false;
  const { operationName, variables } = operation;

  if (graphQLErrors) {
    isUserError = graphQLErrors.every(({ message, extensions = {} }) => {
      const {
        code = '',
        exception = {},
      } = extensions as { code: string, exception: object };

      if (userErrorCodes.includes(code)) {
        return true;
      }

      if (reloadOnSchemaError(code)) {
        return false;
      }

      Sentry.captureMessage(message, {
        tags: {
          operation: operationName,
          code,
        },
        extra: {
          operation: operationName,
          variables: JSON.stringify(variables),
          ...exception,
        },
        level: 'error',
      });
      console.error(`[GraphQL error]: ${message}`, operationName);
      return false;
    });
  }

  if (!isUserError && networkError?.name === 'ServerError') {
    const serverError = networkError as ServerError;
    Sentry.captureException(networkError, {
      tags: {
        operation: operationName,
      },
      extra: {
        operation: operationName,
        variables: JSON.stringify(variables),
        response: JSON.stringify(serverError.response),
        result: JSON.stringify(serverError.result),
      },
    });
    console.error(networkError);
  }
};

export default errorCallback;
