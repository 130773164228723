import { put } from 'redux-saga/effects';
import { ChatAPI } from '@guuru/api-web';
import { client, errorCode } from '@guuru/graphql-web';
import { DEVICES } from '@guuru/chat-common';
import {
  botMessagesTypes,
  getCurrentChatId,
  setCurrentChatId,
  storage,
  types,
  updateChatStep,
  GET_CHAT,
} from '@guuru/chat-web';
import { i18n } from '@guuru/translation-web';
import events from '@guuru/events-web';
import { ChatStep } from '@guuru/constants-common';
import addMessagesNotYetOnAPI from './addMessagesNotYetOnAPI';
import createOrUpdateUser from './createOrUpdateUser';
import updateChatConnectionError from './updateChatConnectionError';
import CREATE_CHAT from './mutations/createChat';

const buildAutomationBehavior = (automationBehavior) => {
  if (!automationBehavior) return {};

  const {
    possibleIntents,
    proposedIntent,
    label,
    subscriptionPriority,
  } = automationBehavior;

  return {
    label,
    proposedIntent,
    subscriptionPriority,
    showDecisionTree: !!possibleIntents,
  };
};
const { isBotTypingVar } = storage;

const getChannel = (partnerId) => {
  const channel = ChatAPI.storeRetrieveChannel(partnerId);
  if (['chat', 'form', 'community'].includes(channel)) {
    return channel;
  }
  return 'chat';
};

export default function* (partnerId) {
  try {
    // if we already have a chat just add the messages that are not yet there
    const currChatId = getCurrentChatId();
    if (currChatId) {
      const controlToken = (
        ChatAPI.storeRetrieveChatControlToken(partnerId, currChatId)
      );
      const messages = yield addMessagesNotYetOnAPI(partnerId, currChatId);
      return {
        updatedChat: { messages: { edges: messages } },
        chatId: currChatId,
        chatControlToken: controlToken,
      };
    }

    const question = ChatAPI.storeRetrieveQuestion();
    const categoryId = ChatAPI.storeRetrieveCategory()
      || ChatAPI.storeRetrieveCategoryParameter();
    const automationBehavior = ChatAPI.storeRetrieveAutomationBehavior(
      partnerId,
    );
    const messages = ChatAPI.storeRetrievePendingMessages();
    const priority = ChatAPI.storeRetrieveChatPriority();
    const locale = ChatAPI.storeRetrieveLocale();
    const params = ChatAPI.storeRetrieveParams();
    const { token, ...tracking } = params;
    const channel = getChannel(partnerId);
    const deviceType = DEVICES.getByWidth(window.screen.availWidth);

    yield createOrUpdateUser(partnerId);
    const {
      data: {
        createChat: createdChat,
      },
    } = yield client.mutate({
      mutation: CREATE_CHAT,
      variables: {
        input: {
          partnerId,
          channel,
          category: categoryId,
          messages,
          languageCode: locale,
          question,
          automationBehavior: buildAutomationBehavior(automationBehavior),
          token,
          priority: priority || params.priority,
          deviceType,
          tracking: {
            ...tracking,
            isUser: ChatAPI.isUser(partnerId),
            isLead: ChatAPI.isLead(partnerId),
          },
          customMeta: ChatAPI.storeRetrieveCustomMeta(partnerId),
        },
      },
    });
    events.newChat(deviceType);

    yield client.writeQuery({
      query: GET_CHAT,
      variables: {
        id: createdChat.id,
        isVisibleByExpert: false,
        isVisibleByUser: true,
      },
      data: {
        chat: createdChat,
      },
    });
    const {
      id: chatId,
      token: chatControlToken,
      createdAt: chatCreatedAt,
      ...updatedChat
    } = createdChat;

    events.chatCreated(chatId);
    ChatAPI.storeChatId(partnerId, chatId);
    ChatAPI.storeResetPendingMessages();
    setCurrentChatId(chatId);
    ChatAPI.storeChatControlToken(partnerId, chatId, chatControlToken);
    updateChatConnectionError(false);

    const chatUrl = ChatAPI.getChatURL(partnerId, channel, {
      chatId,
      token: chatControlToken,
      qs: window.location.search,
    });
    window.history.pushState({}, null, chatUrl);

    return ({
      chatId,
      chatControlToken,
      chatCreatedAt,
      updatedChat,
    });
  } catch (error) {
    const errCode = errorCode(error);
    if (['FORBIDDEN', 'UNAUTHENTICATED'].includes(errCode)) {
      const errorMessage = (errCode === 'FORBIDDEN')
        ? i18n.t(botMessagesTypes.connectionFailed)
        : 'Your token is no longer valid';
      const message = yield ChatAPI.addBotMessage(errorMessage);
      yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
      updateChatStep(ChatStep.hasError);
      isBotTypingVar(false);
      return {};
    }
    updateChatConnectionError(true);
    throw error;
  }
}
