import {
  ForkOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { colors } from '@guuru/constants-common';

const automationActions = {
  greeting: {
    name: 'Greeting Catcher',
    helper: 'Prevent greeting messages from creating a chat',
    icon: RetweetOutlined,
    color: colors.black,
  },
  showAnswer: {
    name: 'Show Answer',
    helper: 'Quick answer to the user based on his question',
    icon: QuestionCircleOutlined,
    color: colors.orange,
  },
  routing: {
    name: 'Routing',
    helper: 'Choose who should answer this question',
    icon: ForkOutlined,
    color: colors.primaryColor,
  },
};

// eslint-disable-next-line import/prefer-default-export
export { automationActions };
