const CHANNELS = {
  chat: {
    id: 'chat',
    label: 'Chat',
    permission: 'partner.chat',
    canTransfer: true,
  },
  form: {
    id: 'form',
    label: 'Form',
    permission: 'partner.form',
    canTransfer: true,
  },
  community: {
    id: 'community',
  },
  mail: {
    id: 'mail',
  },
  facebook: {
    id: 'facebook',
  },
  salesforce: {
    id: 'salesforce',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { CHANNELS };
