import { put, cancel } from 'redux-saga/effects';
import { ChatAPI } from '@guuru/api-web';
import { MESSAGES } from '@guuru/chat-common';
import { types } from '@guuru/chat-web';
import { ChatState, ChatStep } from '@guuru/constants-common';
import { client } from '@guuru/graphql-web';
import { delay as sleep } from '@guuru/helpers-common';
import { i18n } from '@guuru/translation-web';
import createChat from '../../chatActions/createChat';
import handleChatStatusChange from '../../chatActions/handleChatStatusChange';
import addBotPredictionResult from './addBotPredictionResult';
import ACCEPT_BOT_ANSWER from './mutations/acceptBotAnswer';

const { rated } = ChatState;

const addBotAnswer = (automationBehavior, channel) => (
  sleep(ChatAPI.getBotEllipsisMessageDelay())
    .then(() => {
      const { subscriptionAnswer, data } = automationBehavior;
      const text = subscriptionAnswer
        ? subscriptionAnswer[i18n.language]
        : data[i18n.language];
      const messageStyle = channel === 'form' ? 'smartForm' : 'chat';
      const botAnswer = ChatAPI.messages.createMessageType(
        null,
        false,
        MESSAGES.predictionAnswer,
        text,
        { messageStyle },
        { hideForExpert: false },
      );
      ChatAPI.pushPendingMessages(botAnswer);
      return botAnswer;
    })
);

export default function* (automationBehavior, { channel, partnerId }) {
  yield addBotAnswer(automationBehavior, channel);

  // Check if allowFallback
  // yes -> ask user if he is satisfied
  // no  -> close chat
  // (this is done automatically because chat will be ended on api)
  const isSmartForm = channel === 'form';
  if (isSmartForm || automationBehavior.allowFallback) {
    yield addBotPredictionResult(
      partnerId,
      automationBehavior,
    );
  }

  // Create chat
  const {
    chatId,
    chatControlToken,
    updatedChat,
  } = yield createChat(partnerId);

  if (!chatId) yield cancel();

  yield put({
    type: types.CHAT_READY,
    chatId,
    chatControlToken,
    messages: updatedChat?.messages?.edges,
  });

  yield handleChatStatusChange({ chatId, ...updatedChat });
  const hasOptions = automationBehavior.subscriptionOptions?.length > 0;
  if (!hasOptions && updatedChat.status !== rated) {
    yield client.mutate({
      mutation: ACCEPT_BOT_ANSWER,
      variables: { id: chatId },
    });
    return { goToStep: ChatStep.chatEnd };
  }
  return { goToNextStep: false };
}
