import React, { Fragment } from 'react';
import {
  Col, Flex, Grid, Row, Typography,
} from 'antd';
import { useSuspenseQuery } from '@apollo/client';
import { HighlightCard } from '@guuru/react-card';
import { scaleRating } from '@guuru/rating-common';
import { Rating } from '@guuru/react-rating';
import { useTranslation } from 'react-i18next';
import CarouselWrapper from '@guuru/react-carousel';
import { useCommunitySettings } from '@guuru/community-web';
import GET_EXPERTS from './queries/getExperts';
import RelativeTime from '../../../common/RelativeTime';

const { Title } = Typography;

const { useBreakpoint } = Grid;

const slidesToShow = {
  xxl: 4,
  xl: 3,
  sm: 2,
  xs: 1,
};

const BestGuurus = function () {
  const { partnerId, partnerName } = useCommunitySettings();
  const { t, i18n: { language } } = useTranslation();
  const screens = useBreakpoint();

  const {
    data: {
      community: {
        experts: {
          edges: experts = [],
        } = {},
      } = {},
    } = {},
  } = useSuspenseQuery(GET_EXPERTS, {
    variables: {
      partnerId,
      first: 4,
    },
  });

  const breakpoint = Object.keys(slidesToShow).find((size) => screens[size]);
  const canShowList = experts.length >= 2;

  if (!canShowList) {
    return null;
  }

  return (
    <Flex gap={36} vertical>
      <Col align="middle">
        <Title level={3}>
          {t('pages:::discoverTheCommunity', { partnerName })}
        </Title>
      </Col>
      <Row>
        <Col sm={2} md={0} />
        <Col sm={20} md={24}>
          <CarouselWrapper infinite={false} slidesToShow={slidesToShow[breakpoint]} $alignItems="start">
            {experts.map(({ node: { externalId: key, ...expert } }) => (
              <Fragment key={key}>
                <Col style={{ padding: '16px 12px' }}>
                  <HighlightCard
                    key={key}
                    title={expert.name}
                    avatar={expert.photoUrl}
                    description={expert.bio}
                    url={`${process.env.COMMUNITY_ENDPOINT}/${partnerId}/guurus/${key}`}
                    footer={<>{t('pages:::memberSince')} <RelativeTime date={expert.joinedAt} locale={language} /></>}
                    extraTitle={(
                      <Rating
                        rating={scaleRating(expert.rating)}
                        hoverable={false}
                      />
                    )}
                  />
                </Col>
              </Fragment>
            ))}
          </CarouselWrapper>
          <Col sm={2} md={0} />
        </Col>
      </Row>
      <Col align="middle">
        <Title level={4}>{t('pages:::communityGuurusFootNote')}</Title>
      </Col>
    </Flex>
  );
};

export default BestGuurus;
