import {
  ClusterOutlined,
  LinkOutlined,
  NumberOutlined,
  PercentageOutlined,
} from '@ant-design/icons';

const IntentTags = {
  fallbackDisabled: {
    name: 'Fallback disabled',
    color: 'orange',
  },
  frequency: {
    icon: PercentageOutlined,
    help: 'Percentage of utilization of intent in the last 14 days',
  },
  industry: {
    help: 'Industry',
  },
  partner: {
    icon: LinkOutlined,
    color: 'greekblue',
  },
  routeTo: {
    name: 'Default',
    color: 'green',
    help: 'Route to',
  },
  topic: {
    icon: ClusterOutlined,
    color: 'blue',
    help: 'Topic',
  },
  validationSet: {
    icon: NumberOutlined,
    help: 'Number of sample questions',
  },
};

// eslint-disable-next-line import/prefer-default-export
export { IntentTags };
