import React from 'react';
import {
  Button, Col, Grid, Image, Row, Typography,
} from 'antd';
import events from '@guuru/events-web';
import { useTranslation } from 'react-i18next';
import { useCommunitySettings } from '@guuru/community-web';
import Link from '../../Link';

const { Text, Title } = Typography;

const { useBreakpoint } = Grid;

const HowToJoin = function () {
  const {
    partnerName,
    partnerId,
    validationCode,
  } = useCommunitySettings();
  const { xs } = useBreakpoint();
  const { t } = useTranslation();

  const invitationLink = `https://guuru.app.link/?$deeplink_path=community/${partnerId}&~customer_campaign=${partnerId}`;

  const steps = [
    {
      key: 'step2',
      title: (
        <>
          {t('pages:::startStep2', { partnerName })}{' '}
          <Link
            to={invitationLink}
            onClick={() => events.openInvitationLink()}
            target="_blank"
          >{t('pages:::invitationLink')}
          </Link>
          {' '}{t('pages:::endStep2', { partnerName })}
        </>
      ),
      description: validationCode ? (
        <>
          {t('pages:::validationCodeStep2')} <Text strong>{validationCode}</Text>
        </>
      ) : undefined,
    },
    {
      key: 'step3',
      title: t('pages:::step3'),
    },
    {
      key: 'step4',
      title: t('pages:::step4'),
    },
    {
      key: 'step5',
      title: t('pages:::step5'),
    },
  ];

  return (
    <Row justify="center" align="middle" gutter={[0, 24]}>
      <Col xs={24} align="middle">
        <Title level={3}>
          {t('pages:::easy')}
        </Title>
      </Col>
      <Col xs={24}>
        <Row justify="center" gutter={[0, 24]}>
          {steps.map(({
            key, title, description,
          }) => (
            <Col xs={24} key={key}>
              <Row align="middle" justify="center" gutter={[24, 24]}>
                <Col flex="none">
                  <Image
                    width={64}
                    preview={false}
                    src={`https://cdn.guuru.com/assets/pages/invitation/howtojoin/${key}.svg`}
                  />
                </Col>
                <Col flex="460px" align={xs ? 'center' : undefined}>
                  <Title level={5} type="secondary">{title}</Title>
                  {description && (
                    <Text type="secondary">{description}</Text>
                  )}
                </Col>
              </Row>
            </Col>
          ))}
          <Col xs={24} align="middle">
            <Button
              block={xs}
              size="large"
              type="primary"
              shape="round"
              href={invitationLink}
              target="_blank"
              onClick={events.openInvitationLink}
            >
              {t('pages:::joinButton')}
            </Button>
          </Col>
        </Row>
      </Col>

    </Row>
  );
};

export default HowToJoin;
