import { useEffect, useRef, useState } from 'react';

const MINUTE = 60;
const HOUR = 60 * 60;
const DAY = 60 * 60 * 24;

const selectUnit = (seconds: number) => {
  const absValue = Math.abs(seconds);
  if (absValue < MINUTE) return 'second';
  if (absValue < HOUR) return 'minute';
  if (absValue < DAY) return 'hour';
  return 'day';
};

const getDurationInSeconds = (unit: string) => {
  switch (unit) {
    case 'second':
      return 1;
    case 'minute':
      return MINUTE;
    case 'hour':
      return HOUR;
    default:
      return DAY;
  }
};

const INCREMENTABLE_UNITS = ['second', 'minute', 'hour'];
const canIncrement = (unit = 'second') => (
  INCREMENTABLE_UNITS.indexOf(unit) > -1
);

const useElapsedTime = (date: number) => {
  const [
    currentValueInSeconds,
    setCurrentValueInSeconds,
  ] = useState(Math.floor((Date.now() - date) / 1000));
  const updateTimer = useRef<number>();
  const unit = selectUnit(currentValueInSeconds);

  useEffect(() => {
    const clearUpdateTimer = () => {
      clearTimeout(updateTimer.current);
    };
    clearUpdateTimer();
    // If we cannot increment this unit, do nothing
    if (!canIncrement(unit)) {
      return clearUpdateTimer;
    }
    // Figure out the next interesting time
    const currentUnitDuration = getDurationInSeconds(unit);
    const currentValueRemainder = currentValueInSeconds % currentUnitDuration;
    const durationTillNextUnit = currentUnitDuration - currentValueRemainder;
    const nextValueInSeconds = currentValueInSeconds + durationTillNextUnit;
    const nextUnit = selectUnit(nextValueInSeconds);
    // When max incrementable unit is reached, don't schedule another update
    if (nextUnit === 'day') {
      return clearUpdateTimer;
    }

    const unitDuration = getDurationInSeconds(nextUnit);
    const remainder = nextValueInSeconds % unitDuration;
    const prevInterestingValueInSeconds = nextValueInSeconds - remainder;
    const nextInterestingValueInSeconds = durationTillNextUnit > unitDuration
      ? prevInterestingValueInSeconds - unitDuration
      : prevInterestingValueInSeconds;
    const delayInSeconds = (
      Math.abs(nextInterestingValueInSeconds - currentValueInSeconds)
    );

    if (currentValueInSeconds !== nextInterestingValueInSeconds) {
      updateTimer.current = window.setTimeout(
        () => setCurrentValueInSeconds(nextInterestingValueInSeconds),
        delayInSeconds * 1e3,
      );
    }
    return clearUpdateTimer;
  }, [currentValueInSeconds, unit]);

  const unitDuration = getDurationInSeconds(unit);
  const value = Math.floor(currentValueInSeconds / unitDuration);

  return { value, unit };
};

export default useElapsedTime;
