import React from 'react';
import { Alert, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MarkdownRender } from '@guuru/react-markdown-render';
import { useCommunitySettings } from '@guuru/community-web';
import { NotificationOutlined } from '@ant-design/icons';

const ColoredAlert = styled(Alert)`
  padding-block: 10px;
  text-align: center;
  background-color: #f0f0f0;

  a {
    color: ${({ theme }) => theme.onBackgroundColor};
    text-decoration: underline;
  }
`;

const JoinCommunityAlert = function () {
  const { t } = useTranslation();
  const { partnerId, recruiting } = useCommunitySettings();

  if (!recruiting) {
    return null;
  }

  const invitationPageUrl = `${process.env.COMMUNITY_ENDPOINT}/${partnerId}/invitation/?utm_source=pages&utm_medium=banner&utm_content=${partnerId}`;

  return (
    <ColoredAlert
      message={(
        <Space>
          <NotificationOutlined />
          <MarkdownRender content={t('chat:::joinCommunityAlert', { pageUrl: invitationPageUrl })} inline />
        </Space>
    )}
      banner
      showIcon={false}
      type="info"
    />
  );
};

export default JoinCommunityAlert;
