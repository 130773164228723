import React from 'react';
import { Badge, Space } from 'antd';
import { TrophyFilled } from '@ant-design/icons';
import { Avatar } from '@guuru/react-expert-avatar';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const trophyTypes = {
  gold: 'rgba(255, 223, 133, 0.7)',
  silver: 'rgba(180, 180, 220, 0.7)',
  bronze: 'rgba(205, 127, 50, 0.7)',
};

const AVAILABLE_TROPHIES = Object.keys(trophyTypes);

const BadgeTrophy = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: #fff;
  border: ${({ type }) => `1px solid ${trophyTypes[type]}`};
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 2px 0;

  span {
    color: ${({ type }) => trophyTypes[type]}
  }
`;

const Guuru = function ({ index, expert }) {
  const { photoUrl, name } = expert;
  const avatar = (
    <Avatar
      size="large"
      shape="square"
      src={photoUrl}
      text={name}
    />
  );
  return (
    <Space size={16}>
      {index <= 2 ? (
        <Badge
          offset={[-45, 2]}
          count={(
            <BadgeTrophy type={AVAILABLE_TROPHIES[index]}>
              <TrophyFilled />
            </BadgeTrophy>
          )}
        >
          {avatar}
        </Badge>
      ) : avatar}
      {name}
    </Space>
  );
};

Guuru.propTypes = {
  index: PropTypes.number.isRequired,
  expert: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default Guuru;
