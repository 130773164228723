const { localesData } = require('./locales');
const features = require('./features');

const Namespaces: {[key: string]: string} = {
  mobile: 'Mobile',
  pages: 'Pages',
  api: 'API',
  chat: 'Chat',
  bot: 'Bot',
  feedback: 'Feedback',
  language: 'Language',
};

const TranslationsNamespaces = Object.keys(Namespaces).map((ns) => ({
  name: ns,
  value: Namespaces[ns].toLowerCase(),
}));

const PartnerFeatures = Object.keys(features).map((feature) => ({
  name: feature,
  value: feature,
}));

const Features = [
  ...PartnerFeatures,
  {
    name: 'review',
    value: 'review',
  },
  {
    name: 'recruiting',
    value: 'recruiting',
  },
  {
    name: 'expert view',
    value: 'expertView',
  },
  {
    name: 'guuru activity notifications',
    value: 'expertsInactiveMail',
  },
];

const formal = {
  key: 'formal',
  name: 'Formal Values',
};

interface NamespaceFilters {
  showAll: boolean;
}

interface FeatureFilters {
  showAll: boolean;
  enabledFeatures: { [Key: string]: boolean};
}

interface LanguageFilters {
  activeLanguages: string[];
}

const TranslationsFilters = {

  namespace: {
    key: 'namespace',
    label: 'Namespace',
    getFilters: ({ showAll }: NamespaceFilters) => (
      showAll ? TranslationsNamespaces : []
    ),
  },

  feature: {
    key: 'feature',
    label: 'Feature',
    type: 'featureStatus',
    getFilters: ({ showAll, enabledFeatures }: FeatureFilters) => {
      const currentFilter = showAll ? Features : PartnerFeatures;
      return currentFilter.map(({ name, value }) => ({
        value,
        name,
        enabled: enabledFeatures[value] || false,
        showStatusDot: !showAll,
      }));
    },
  },

  languages: {
    key: 'language',
    label: 'Language',
    type: 'language',
    getFilters: ({ activeLanguages }: LanguageFilters) => (
      activeLanguages.map((key) => ({
        name: localesData[key] ? localesData[key].name : key,
        value: key,
      }))
    ),
  },

  searchText: {
    key: 'searchText',
  },

  language: {
    key: 'language',
  },

  keys: {
    key: 'keys',
  },
  formal,
  filterBy: {
    key: 'tanslationFilters',
    label: 'Show Only',
    filters: [formal],
  },
};

export { Namespaces, TranslationsFilters, Features };
