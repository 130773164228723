import React from 'react';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const ExpandIcon = function ({ expanded, onExpand, record }) {
  if (record.id === 'separator') {
    return null;
  }
  const IconNode = expanded ? CaretUpFilled : CaretDownFilled;
  return (
    <IconNode
      style={{ fontSize: 18, padding: '20px 10px' }}
      onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
    />
  );
};

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default ExpandIcon;
