const HELPER = {
  join: (list: string[], separator: string, lastSeparator: string) => {
    if (!list) {
      return '';
    }
    if (list.length === 1) {
      return list[0];
    }
    if (list.length === 2) {
      return list.join(lastSeparator);
    }
    if (list.length > 2) {
      return [list.slice(0, -1).join(', '), list.slice(-1)].join(lastSeparator);
    }
    return '';
  },
};

export default HELPER;
