import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';

const Separator = styled.span`
  line-height: 25px;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 24px;
  padding-left: 45px;
`;

const RowSeparator = function () {
  return <Separator><EllipsisOutlined /></Separator>;
};

export default RowSeparator;
