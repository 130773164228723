import React from 'react';
import { Badge, ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { colors } from '@guuru/constants-common';

const Score = function ({ score, height = 32, fontSize = 13 }) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            indicatorHeight: height,
            textFontSize: fontSize,
          },
        },
      }}
    >
      <Badge
        overflowCount={1000}
        count={score}
        color={colors.blue}
      />
    </ConfigProvider>
  );
};

Score.propTypes = {
  score: PropTypes.number.isRequired,
  height: PropTypes.number,
  fontSize: PropTypes.number,
};

export default Score;
