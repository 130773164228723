import styled from 'styled-components';
import { Carousel } from 'antd';

interface CarouselWrapperProps {
  $alignItems?: string;
}

const CarouselWrapper = styled(Carousel)<CarouselWrapperProps>`

  .slick-dots {
    margin-top: 20px;
    position: relative;

    li {
      background: grey;

      &.slick-active::after {
        background: ${({ theme }) => `${theme.primaryColor}`};
      }
    }
  }

  .slick-dots-bottom {
    bottom: 0;
  }

  .slick-prev {
    font-size: inherit;
    color:  ${({ theme }) => `${theme.primaryColor}`};
  }

  .slick-next {
    font-size: inherit;
    color:  ${({ theme }) => `${theme.primaryColor}`};
  }

  .slick-track {
    text-align: ${(props) => (props.$alignItems ? 'inherit' : 'center')};
    display: flex;
    align-items: ${(props) => props.$alignItems ?? 'center'};
  }
`;

export default CarouselWrapper;
