import React from 'react';
import Good from '../assets/good.svg';
import Average from '../assets/average.svg';
import Bad from '../assets/bad.svg';

const Components = {
  good: Good,
  average: Average,
  bad: Bad,
} as const;

interface EmojiProps {
  type: keyof typeof Components;
  props?: React.SVGAttributes<SVGElement>;
  width?: number;
}

const Emoji = function ({ type, width = 28, ...props }: EmojiProps) {
  const Component = Components[type];
  return (
    <Component
      width={width}
      key={type}
      height={width}
      {...props}
    />
  );
};

export default Emoji;
