import React from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { FreshchatOutlined } from '@guuru/react-icon';

const { Text } = Typography;

interface ExternalIconProps {
  provider: string;
}

const ExternalIcon = function ({ provider }: ExternalIconProps) {
  let icon;
  switch (provider) {
    case 'freshchat': {
      icon = <FreshchatOutlined />;
      break;
    }
    default:
  }
  if (!icon) {
    return null;
  }
  return <Tooltip title={provider}>{icon}</Tooltip>;
};

interface ExpertNameProps {
  expert?: {
    name: string;
    provider?: string;
    id: string;
  };
  isExternal: boolean;
}

const ExpertName = function ({
  isExternal,
  expert = undefined,
}: ExpertNameProps) {
  if (!expert?.name) {
    return '<not available>';
  }
  const provider = expert.provider || expert.id;
  return (
    <Space size={4}>
      <Text>{expert.name}</Text>
      {isExternal && <ExternalIcon provider={provider} />}
    </Space>
  );
};

export default ExpertName;
