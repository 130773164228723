import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { BOTS } from '@guuru/expert-common';
import Message from './Message';
import { Col } from '../IntentSelection/style';
import { SmartFormText } from './style';
import BotAnswer from './BotAnswer';
import MessageTooltip from '../../MessageTooltip';

const { Text } = Typography;

const ChatRenderText = function ({
  message,
  tooltip = true,
  children = null,
  loading = false,
  note = false,
}) {
  const { smartFormStyle, author } = message;
  // eslint-disable-next-line no-underscore-dangle
  if (author?.__typename === 'UserAuthor' && smartFormStyle) {
    return (
      <Col xs={24} md={11} className="chat-content-intent-selection-message">
        <SmartFormText>{message.i18n}</SmartFormText>
      </Col>
    );
  }

  if (note) {
    return <Text style={{ textAlign: 'center' }}>{message.i18n}</Text>;
  }

  if (loading || message.type === 'typing' || message.type === 'connecting') {
    return <Message message={{ i18n: '', type: 'typing' }} />;
  }

  if (author?.id === BOTS.copilot.externalId) {
    return <BotAnswer message={message} />;
  }

  const msg = <Message message={message}>{children}</Message>;
  return tooltip
    ? <MessageTooltip message={message}>{msg}</MessageTooltip>
    : msg;
};

ChatRenderText.propTypes = {
  message: PropTypes.shape({
    smartFormStyle: PropTypes.bool,
    author: PropTypes.shape({
      id: PropTypes.string,
      __typename: PropTypes.string,
    }),
    text: PropTypes.string,
    i18n: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  tooltip: PropTypes.bool,
  children: PropTypes.node,
  loading: PropTypes.bool,
  note: PropTypes.bool,

};

export default ChatRenderText;
