const SERVER_HOST = process.env.CORE_ENDPOINT;

const ENDPOINTS = {
  ENDPOINT__PARTNER_REPORTING_DOWNLOAD: `${SERVER_HOST}/partners/:partnerId/reporting/:reportId/download`,
};

const DEFAULT_AVATAR_PHOTO = 'https://cdn.guuru.com/assets/logo/avatar.png';
const buildExpertWebUrl = (chatId: string) => (
  `${process.env.EXPERT_WEB_ENDPOINT}/chats/${chatId}`
);

export {
  ENDPOINTS,
  DEFAULT_AVATAR_PHOTO,
  buildExpertWebUrl,
};
