import styled from 'styled-components';
import { Typography } from 'antd';

const Paragraph = styled(Typography.Paragraph)`
  font-size: clamp(14px, 2.5vw, 16px);
  color: rgb(107 114 128);
`;

// eslint-disable-next-line import/prefer-default-export
export { Paragraph };
