const sessionStorageHelper = {
  getItem: (key: string) => {
    try {
      return sessionStorage[key];
    } catch (e) {
      return null;
    }
  },

  setItem: (key: string, value: any) => {
    try {
      sessionStorage.setItem(key, value);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  },

  removeItem: (key: string) => {
    try {
      sessionStorage.removeItem(key);
    // eslint-disable-next-line no-empty
    } catch (e) {}
  },
};

export default sessionStorageHelper;
