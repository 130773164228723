import { ChatAPI } from '@guuru/api-web';
import { cancel, put } from 'redux-saga/effects';
import { client } from '@guuru/graphql-web';
import { MESSAGES } from '@guuru/chat-common';
import { getCurrentChatId, types } from '@guuru/chat-web';
import { ChatStep } from '@guuru/constants-common';
import { i18n } from '@guuru/translation-web';
import { delay as sleep, LocaleHelper } from '@guuru/helpers-common';
import CONFIRM_INTENT_SELECTION from './mutations/confirmIntentSelection';
import GET_PREDICTION from './queries/prediction';
import createChat from '../../chatActions/createChat';

const addIntentSelectionMessage = (partnerId, intents) => (
  sleep(ChatAPI.getBotEllipsisMessageDelay())
    .then(() => {
      const predictionSelectionSmartForm = 'bot:::predictionSelectionSmartForm';
      const messageStyle = ChatAPI.getMessageStyle(partnerId);
      const message = ChatAPI.messages.createMessageType(
        null,
        false,
        MESSAGES.intentSelection,
        predictionSelectionSmartForm,
        { messageStyle, intents },
        { hideForExpert: false },
      );
      ChatAPI.pushPendingMessages(message);
      return message;
    })
);

const intentSelectedMessage = (partnerId, title) => {
  const locale = ChatAPI.storeRetrieveLocale();
  const messageStyle = ChatAPI.getMessageStyle(partnerId);
  const intentSelected = ChatAPI.messages.createAnonymousTextMessage(
    LocaleHelper.localize(title, locale),
    false,
    false,
    { messageStyle },
  );
  ChatAPI.pushPendingMessages(intentSelected);
  return intentSelected;
};

export default {

  // we only need to run this if
  // we have possibleIntents on automationBehavior obj.
  validate(_, { skipAutomation } = {}) {
    const priority = ChatAPI.storeRetrieveChatPriority();
    return !skipAutomation && !priority;
  },

  * render(partnerId) {
    const question = ChatAPI.storeRetrieveQuestion();
    const channel = ChatAPI.storeRetrieveChannel(partnerId);
    const params = ChatAPI.storeRetrieveParams();
    ChatAPI.storeAutomationBehavior(partnerId, null);
    const {
      data: {
        partner: {
          predict: {
            intentSubscription,
            proposedIntent,
            possibleIntents,
          } = {},
        },
      },
    } = yield client.query({
      query: GET_PREDICTION,
      variables: {
        channel,
        partnerId,
        question,
        params,
        languageCode: i18n.language,
      },
    });

    const automationBehavior = {
      ...intentSubscription,
      proposedIntent,
      possibleIntents,
    };
    ChatAPI.storeAutomationBehavior(partnerId, automationBehavior);

    if (!possibleIntents) {
      return {
        goToStep: ChatStep.makePrediction,
        nextStepOptions: {
          automationBehavior,
        },
      };
    }

    yield addIntentSelectionMessage(partnerId, possibleIntents);

    const {
      chatId,
      chatControlToken,
      updatedChat,
    } = yield createChat(partnerId);

    if (!chatId) yield cancel();

    yield put({
      type: types.CHAT_READY,
      chatId,
      chatControlToken,
      messages: updatedChat?.messages?.edges,
    });

    return {};
  },
  * processResponse(partnerId, { automationBehavior }) {
    const { title, label } = automationBehavior;
    const intentMessage = intentSelectedMessage(partnerId, title);
    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message: intentMessage });

    ChatAPI.storeAutomationBehavior(partnerId, automationBehavior);

    client.mutate({
      mutation: CONFIRM_INTENT_SELECTION,
      variables: {
        input: {
          chatId: getCurrentChatId(),
          label,
        },
      },
    });

    return {
      goToStep: ChatStep.makePrediction,
      nextStepOptions: { automationBehavior },
    };
  },
};
