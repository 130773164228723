import { localesData } from '@guuru/constants-common';
import { GuuruOutlined } from '@guuru/react-icon';
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CustomerServiceOutlined,
  Loading3QuartersOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';

interface Filters {
  showAll?: boolean;
  partner?: {
    settings: {
      agents: {
        isEnabled: boolean,
      }
      translations: {
        enabledLanguages: string[],
      }
    }
    categories: {
      name: string,
      id: string,
    }[],
  };
}

const isGuuru = {
  key: 'expertRole',
  name: 'Guuru',
  icon: GuuruOutlined,
  value: 'guuru',
};

const isAgent = {
  key: 'expertRole',
  name: 'Agent',
  icon: CustomerServiceOutlined,
  value: 'agent',
};

const isPartnerSuperGuuru = {
  key: 'expertRole',
  name: 'Super Guuru',
  icon: GuuruOutlined,
  value: 'superGuuru',
};

const isSuperGuuru = {
  key: 'isSuperGuuru',
  name: 'Super Guuru',
  icon: GuuruOutlined,
};

const availableNow = {
  key: 'availableNow',
  name: 'Available now',
  icon: ClockCircleOutlined,
};

const active = {
  key: 'active',
  name: 'Active',
  icon: CheckOutlined,
};

const disabled = {
  key: 'disabled',
  name: 'Disabled',
  icon: CloseCircleOutlined,
};

const onboarding = {
  key: 'onboarding',
  name: 'Onboarding',
  icon: QuestionCircleOutlined,
};

const banned = {
  key: 'banned',
  name: 'Banned',
  icon: CloseCircleOutlined,
};

const notBanned = {
  key: 'notBanned',
  name: 'Not Banned',
  icon: CheckCircleOutlined,
};

const isHuman = {
  key: 'isHuman',
  name: 'Is Human',
};

const chatsDisabled = {
  key: 'chatsDisabled',
  name: 'Chats Disabled',
  icon: MinusCircleOutlined,
};

const chatsEnabled = {
  key: 'chatsEnabled',
  name: 'Chats Enabled',
  icon: PlusCircleOutlined,
};

const inactive = {
  key: 'inactive',
  name: 'Inactive',
  icon: PoweroffOutlined,
};

const reviewStatusConfirmed = {
  name: 'Confirmed',
  icon: CheckCircleOutlined,
  value: 'reviewStatusConfirmed',
};

const reviewStatusInProgress = {
  name: 'In Progress',
  icon: Loading3QuartersOutlined,
  value: 'reviewStatusInProgress',
};

const reviewStatusActionNeeded = {
  name: 'Action Needed',
  icon: WarningOutlined,
  value: 'reviewStatusActionNeeded',
};

const ExpertFilters = {
  isHuman,
  isGuuru,
  isAgent,
  isSuperGuuru,
  isPartnerSuperGuuru,
  monitorActiveExperts: {
    key: 'monitorActiveExperts',
  },
  searchText: {
    key: 'searchText',
  },
  partnerBucket: {
    key: 'partnerBucket',
  },
  categories: {
    key: 'categories',
    label: 'Categories',
    getFilters: ({ partner }: Filters) => (
      partner
        ? partner.categories.filter((c) => c.name).map((item) => (
          { name: item.name, value: item.id, icon: AppstoreOutlined }
        ))
        : null
    ),
  },
  language: {
    key: 'language',
    label: 'Language',
    type: 'language',
    filters: Object.entries(localesData).map(([key, { name }]) => (
      { name, value: key }
    )),
  },
  enabledLanguages: {
    key: 'language',
    label: 'Language',
    type: 'language',
    getFilters: ({ partner }: Filters) => (
      partner
        ? Object.entries(localesData)
          .filter(([key]) => partner.settings.translations.enabledLanguages
            .includes(key)).map(([key, { name }]) => (
            { name, value: key }))
        : null
    ),
  },
  expertType: {
    key: 'expertRole',
    label: 'Type',
    filters: [
      isGuuru,
      {
        ...isAgent,
        check: ({ showAll, partner }: Filters) => (
          showAll || partner?.settings?.agents?.isEnabled
        ),
      },
    ],
  },
  expertRole: {
    key: 'expertRole',
    label: 'Role',
    filters: [isPartnerSuperGuuru],
  },
  availableNow,
  active,
  inactive,
  disabled,
  onboarding,
  chatsDisabled,
  chatsEnabled,
  banned,
  notBanned,
  filterBy: {
    key: 'filterBy',
    label: 'Show Only',
    filters: [
      availableNow,
      active,
      inactive,
      onboarding,
      chatsEnabled,
      chatsDisabled,
    ],
  },

  reviewStatusConfirmed,
  reviewStatusInProgress,
  reviewStatusActionNeeded,
  progressStatus: {
    key: 'progressStatus',
    label: 'Progress Status',
    filters: [
      reviewStatusConfirmed,
      reviewStatusInProgress,
      reviewStatusActionNeeded,
    ],
  },
};

// eslint-disable-next-line import/prefer-default-export
export { ExpertFilters };
