import React from 'react';
import {
  Flex, Space, Tooltip, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const AttributeInfo = function ({
  title,
  extra,
  icon: Icon,
  tooltip = null,
}) {
  return (
    <Space size={8}>
      <Icon style={{ fontSize: 'clamp(20px, 2.5vw, 30px)' }} />
      <Flex vertical>
        <Text type="secondary">
          <Space>
            {title}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Space>
        </Text>
        {extra}
      </Flex>
    </Space>
  );
};

AttributeInfo.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  extra: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
};

export default AttributeInfo;
