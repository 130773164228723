import React from 'react';
import { Card as AntCard } from 'antd';
import styled from 'styled-components';

import type { CardProps as AntCardProps } from 'antd';

const StyledCard = styled(AntCard)`
  .ant-card-body:has(.ant-collapse) {
    padding: 8px 16px;
  }
`;

interface CardProps extends AntCardProps {
  visible?: boolean;
  white?: boolean;
}

const Card = function ({
  visible = true,
  white = true,
  children,
  ...props
}: CardProps) {
  if (!visible) return null;

  return (
    <StyledCard
      className={white ? 'ant-card-empty-white' : undefined}
      {...props}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
