import React from 'react';
import {
  Col,
  Divider,
  Flex,
  Grid,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  HeartOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Paragraph } from './styles';
import AttributeInfo from '../AttributeInfo';
import Score from '../Columns/Score';
import PageLink from './PageLink';

const { useBreakpoint } = Grid;

const Title = styled(Typography.Title)`
  margin: 0;

  ${({ $isSmallDevice }) => $isSmallDevice && `
    text-align: center
  `}
`;

const FooterContent = styled(Row)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Footer = function ({ partnerId, showStyleColumn = false }) {
  const breakpoints = useBreakpoint();

  const isSmallDevice = !breakpoints.sm;

  return (
    <FooterContent gutter={[undefined, 12]}>
      <Col xs={24}>
        <Title $isSmallDevice={isSmallDevice} level={isSmallDevice ? 3 : 2}>
          What Drives Your Score
        </Title>
      </Col>
      <Col xs={24}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12}>
            <Paragraph>
              We truly value <PageLink
                partnerId={partnerId}
                path="answering"
                text="meaningful connections"
              />.
              When you share real
              experiences, go the extra mile, and let your profile reflect the
              real you, it makes all the difference - creating conversations
              that leave users feeling supported and happy. Your score reflects
              how well you shine in these areas over your last 40
              conversations. <PageLink
                partnerId={partnerId}
                path="score"
                text="Learn more."
              />
            </Paragraph>
            <Col xs={0} sm={24} style={{ padding: 0 }}>
              <Paragraph>
                Check your chat history to see how your conversations scored.
              </Paragraph>
            </Col>
          </Col>
          <Col xs={24} sm={12}>
            <Row gutter={[0, 24]}>
              <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <AttributeInfo
                  icon={SmileOutlined}
                  title="Happiness"
                  extra={(
                    <Space size={2} split={<Divider type="vertical" />}>
                      <Space size={6}>
                        Good Rating
                        <Score score={2} />
                      </Space>
                      <Space size={6}>
                        Written Feedback
                        <Score score={5} />
                      </Space>
                    </Space>
                  )}
                />
              </Col>
              {showStyleColumn && (
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <Flex vertical gap={5}>
                    <AttributeInfo
                      icon={HeartOutlined}
                      title="Style"
                      tooltip="Style evaluation is done automatically."
                      extra={(
                        <Space size={2} split={<Divider type="vertical" />}>
                          <Space size={6}>
                            Exceptional
                            <Score score={10} />
                          </Space>
                          <Space size={6}>
                            Good
                            <Score score={1} />
                          </Space>
                        </Space>
                      )}
                    />
                  </Flex>
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <AttributeInfo
                  icon={UserOutlined}
                  title="Profile"
                  extra={(
                    <Space size={6}>
                      Complete
                      <Score score={10} />
                    </Space>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={0} style={{ marginTop: '15px' }}>
        <Paragraph>
          See your conversation history to see how your conversations scored.
        </Paragraph>
      </Col>
    </FooterContent>
  );
};

Footer.propTypes = {
  showStyleColumn: PropTypes.bool,
  partnerId: PropTypes.string.isRequired,
};

export default Footer;
