import React, { useEffect, useState, useCallback } from 'react';
import { string, shape, bool } from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Row } from 'antd';
import { MESSAGES } from '@guuru/chat-common';
import { ChatState } from '@guuru/constants-common';
import events from '@guuru/events-web';
import { i18n as translations } from '@guuru/translation-web';
import useWaitTimeout from './hooks/useWaitTimeout';
import ChatRenderText from '../Text';
import Option from '../../Option';
import TRANSFER_BY_EMAIL from './mutations/transferByEmail';
import GET_CHAT from './queries/getChatWaitTimeout';

const { created, assigned } = ChatState;

const ChatRenderWaitTimeout = function ({
  message,
  chatId = null,
  readOnly = false,
}) {
  const {
    i18n,
    metadata: {
      waitTimeoutStatus,
    } = {},
    id,
  } = message;

  const [transfer] = useMutation(TRANSFER_BY_EMAIL);
  const [newMessage, setNewMessage] = useState();
  const [
    currentTimeoutStatus,
    setCurrentTimeoutStatus,
  ] = useState(MESSAGES.emailWaitTimeout);
  const [currentChatStatus, setCurrentChatStatus] = useState();

  const {
    data: {
      chat: {
        status,
      } = {},
    } = {},
    loading,
  } = useQuery(GET_CHAT, {
    variables: {
      id: chatId,
    },
  });

  const shouldSkipSubscribe = currentTimeoutStatus !== MESSAGES.emailWaitTimeout
    || ![created, assigned].includes(currentChatStatus);

  const waitTimeoutUpdate = useWaitTimeout(
    id,
    chatId,
    shouldSkipSubscribe,
  );

  const handleClick = useCallback(async (mutation) => {
    setCurrentChatStatus('handover');
    events.transferByEmail();

    await mutation({
      variables: { chatId },
    });
  }, [chatId, setCurrentChatStatus]);

  const triggerAnalytics = useCallback(() => {
    if (![created, assigned].includes(currentChatStatus)) {
      return null;
    }
    return events.emailWaitTimeout();
  }, [currentChatStatus]);

  useEffect(() => {
    setNewMessage({ i18n });
    setCurrentTimeoutStatus(waitTimeoutStatus);
    triggerAnalytics();
  }, [i18n, waitTimeoutStatus, triggerAnalytics]);

  useEffect(() => {
    setCurrentChatStatus(status);
  }, [status]);

  useEffect(() => {
    if (waitTimeoutUpdate) {
      setNewMessage({ i18n: waitTimeoutUpdate.text });
      setCurrentTimeoutStatus(waitTimeoutUpdate.waitTimeoutStatus);
      triggerAnalytics();
    }
  }, [waitTimeoutUpdate, triggerAnalytics]);

  if (loading || newMessage === undefined) {
    return null;
  }

  const transferOptions = {
    key: 'transferByEmailOption',
    translatedText: translations.t('bot:::transferByEmail'),
    action: transfer,
  };

  return (
    <ChatRenderText message={newMessage} tooltip={false}>
      {([created, assigned].includes(currentChatStatus))
      && !readOnly
      && (
        <Row className="message-options">
          <ul className="message-options-list">
            <Option
              id={transferOptions.action}
              name={transferOptions.key}
              onClick={handleClick}
            >
              {transferOptions.translatedText}
            </Option>
          </ul>
        </Row>
      )}
    </ChatRenderText>
  );
};

ChatRenderWaitTimeout.propTypes = {
  message: shape({}).isRequired,
  chatId: string,
  readOnly: bool,
};

export default ChatRenderWaitTimeout;
