import { ChatAPI } from '@guuru/api-web';
import events from '@guuru/events-web';
import { put } from 'redux-saga/effects';
import { MESSAGES } from '@guuru/chat-common';
import { types } from '@guuru/chat-web';

const createIntroWelcome = (message) => ChatAPI.messages.createBotMessage(
  new Date().getTime(),
  message,
  MESSAGES.welcome,
  {},
  true,
  false,
);

export default {
  validate() {
    return true;
  },

  * render(partnerId, { welcomeMessage }) {
    events.welcome();
    const message = welcomeMessage || createIntroWelcome('bot:::welcome');
    ChatAPI.pushPendingMessages(message);
    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
    return { goToNextStep: true };
  },

  processResponse() {},
};
