import ms from 'ms';

const matchingConfig = {
  retryMs: ms('45s'),
  expertSelection: [
    {
      status: 'integrations',
      includeOnlyExpertsInCategory: false,
    }, {
      status: 'aboveThreshold',
      includeOnlyExpertsInCategory: true,
    }, {
      status: 'aboveThreshold',
      includeOnlyExpertsInCategory: false,
    },
  ],
  // pre-compute thresholds
  expertInactiveDays: 30,
  ratingAboveThresholdWindow: 40,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  matchingConfig,
};
