const MESSAGES = {
  typing: 'typing',
  message: 'message',
  contact: 'contact',
  attachment: 'attachment',
  category: 'category',
  predictionResult: 'predictionConfirmation',
  predictionAnswer: 'predictionAnswer',
  requestRating: 'requestRating',
  waitTimeout: 'waitTimeout',
  emailWaitTimeout: 'emailWaitTimeout',
  smsFallback: 'smsFallback',
  waitFallback: 'waitFallback',
  visitorRole: 'visitorRole',
  partnerSelection: 'partnerSelection',
  quickActionSelection: 'quickActionSelection',
  intentSelection: 'intentSelection',
  userInfo: 'userInfo',
  emoji: 'emoji',
  welcome: 'welcome',
  button: 'button',
};

// eslint-disable-next-line import/prefer-default-export
export { MESSAGES };
