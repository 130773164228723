/* eslint-disable default-param-last */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { BOTS } from '@guuru/expert-common';
import { MESSAGES } from '@guuru/chat-common';
import { i18n } from '@guuru/translation-web';
import {
  visitorMessage,
  setCurrentChatId,
  setFeedbackSubmitted,
  GET_CHAT,
  CHAT_SETTINGS,
} from '@guuru/chat-web';
import {
  localStorageHelper,
  sessionStorageHelper,
  requestHelper,
  delay as sleep,
} from '@guuru/helpers-common';
import { client, errorCode, errorType } from '@guuru/graphql-web';
import SEND_CHAT_MESSAGE from './mutations/sendChatMessage';

const CHAT_DATA_FIELD_CHATID = 'chatId';
const CHAT_DATA_FIELD_CHAT_CONTROL_TOKEN = 'chatControlToken';
const BOT_TYPING_DELAY_MIN = 100;
const BOT_TYPING_DELAY_MAX = 200;
const BOT_TYPING_ELLIPSIS_MIN = 500;
const BOT_TYPING_ELLIPSIS_MAX = 1000;

const context = (() => {
  const store = {};

  return {
    get: (prop, fallback) => {
      if (fallback !== undefined) {
        return store[prop] || fallback;
      }
      return store[prop];
    },
    set: (props) => Object.assign(store, props),
    push: (prop, value) => {
      if (prop in store) {
        store[prop].push(value);
      } else {
        store[prop] = [value];
      }
    },
  };
})();

const getPendingInputKey = (chatId) => `guuru.chat.${chatId}.pendingInput`;

/**
 * API: All chat related methods
 */
const API = {
  /** Chat transcript Messaging helpers */
  messages: {
    createMessage: (
      uid,
      name,
      coverImage,
      isBot,
      isUser,
      isExpert,
      message,
      type,
      timestamp,
      data = {},
      hideForExpert,
      hideForUser,
    ) => ({
      author: {
        uid,
        name,
        coverImage,
        isBot,
        isUser,
        isExpert,
      },
      data,
      message,
      i18nData: data,
      i18n: i18n.t(message, data),
      id: uuidv4(),
      type: type || MESSAGES.message,
      timestamp,
      hideForExpert: hideForExpert || false,
      hideForUser: hideForUser || false,
    }),

    createBotMessage: (
      timestamp,
      message,
      type,
      data,
      hideForExpert,
      hideForUser,
    ) => API.messages.createMessage(
      BOTS.bot.externalId,
      BOTS.bot.name,
      BOTS.bot.photoUrl,
      true,
      false,
      false,
      message,
      type,
      timestamp,
      data,
      hideForExpert,
      hideForUser,
    ),

    createAnonymousTextMessage: (message, hideForExpert, hideForUser, data) => (
      API.messages.createMessage(
        null,
        '',
        null,
        false,
        true,
        false,
        message,
        MESSAGES.message,
        moment().valueOf(),
        data || {},
        hideForExpert,
        hideForUser,
      )
    ),

    craeteBotMessage: (timestamp, botMessageCode, data = {}) => (
      API.messages.createBotMessage(
        timestamp || moment().valueOf(),
        botMessageCode,
        MESSAGES.message,
        data,
        true,
        false,
      )
    ),

    createMessageType: (
      timestamp,
      asSimpleaMessage,
      type,
      message,
      data,
      { hideForExpert = true, hideForUser = false } = {},
    ) => (
      API.messages.createBotMessage(
        timestamp || moment().valueOf(),
        message,
        asSimpleaMessage ? MESSAGES.message : type,
        data || {},
        hideForExpert,
        hideForUser,
      )
    ),
  },

  getBotDelayMessageDelay: () => (
    requestHelper.randomInt(BOT_TYPING_DELAY_MIN, BOT_TYPING_DELAY_MAX)
  ),

  getBotEllipsisMessageDelay: () => (
    requestHelper.randomInt(BOT_TYPING_ELLIPSIS_MIN, BOT_TYPING_ELLIPSIS_MAX)
  ),

  storePartnerId: (partnerId) => {
    context.set({ partnerId });
  },

  storeLocale: (locale) => {
    context.set({ locale });
  },

  storeName: (partnerId, name) => {
    context.set({ name });
  },

  storeQuestion: (partnerId, question) => {
    context.set({ question });
  },

  storeUserPredictionAnswer: (partnerId, userPredictionAnswer) => {
    context.set({ userPredictionAnswer });
  },

  storeParams: (params) => {
    context.set({ params });
  },

  storeCustomMeta: (partnerId, customMeta) => {
    context.set({ customMeta });
  },

  storeRetrievePartnerId: () => (
    context.get('partnerId')
  ),

  storeRetrieveLocale: () => (
    context.get('locale')
  ),

  storeRetrieveParams: () => (
    context.get('params', {})
  ),

  storeRetrieveCategory: () => (
    context.get('category')
  ),

  storeRetrieveCategoryParameter: () => (
    context.get('categoryParameter')
  ),

  storeRetrieveLocaleParameter: () => (
    context.get('localeParameter')
  ),

  storeRetrieveToken: () => {
    const params = context.get('params', {});
    return params?.token;
  },

  storeRetrieveCategorySelected: () => (
    context.get('categorySelected', false)
  ),

  storeRetrieveQuestion: () => (
    context.get('question')
  ),

  storeRetrieveUserPredictionAnswer: () => (
    context.get('userPredictionAnswer')
  ),

  storeRetrieveCustomMeta: () => (
    context.get('customMeta')
  ),

  storeAutomationBehavior: (partnerId, automationBehavior) => (
    localStorageHelper.setObject(partnerId, 'automationBehavior', automationBehavior)
  ),

  storeRetrieveAutomationBehavior: (partnerId) => (
    localStorageHelper.getObject(partnerId, 'automationBehavior')
  ),

  storeRetrieveName: () => (
    context.get('name', null)
  ),

  storeRetrieveEmail: () => (
    context.get('email', null)
  ),

  storeRetrievePendingMessages: () => (
    context.get('pendingMessages', [])
  ),

  storeRetrieveChatId: (partnerId) => (
    localStorageHelper.getObject(partnerId, CHAT_DATA_FIELD_CHATID)
  ),

  storeRetrieveChannel: (partnerId) => (
    localStorageHelper.getObject(partnerId, 'channel')
  ),

  storeChatControlToken: (partnerId, chatId, chatControlToken) => (
    localStorageHelper.pushObjectForChat(
      partnerId,
      chatId,
      { chatId, timestamp: moment().valueOf(), chatControlToken },
    )
  ),

  storeRetrieveChatControlToken: (partnerId, chatId) => (
    localStorageHelper.getObjectForChat(
      partnerId,
      chatId,
      CHAT_DATA_FIELD_CHAT_CONTROL_TOKEN,
    )
  ),

  storeChatUserPendingInput: (chatId, text) => {
    if (!chatId || !text) return;
    sessionStorageHelper.setItem(getPendingInputKey(chatId), text);
  },

  storeRetrieveChatUserPendingInput: (chatId) => {
    if (!chatId) return null;
    return sessionStorageHelper.getItem(getPendingInputKey(chatId));
  },

  deleteChatUserPendingInput: (chatId) => {
    if (!chatId) return;
    sessionStorageHelper.removeItem(getPendingInputKey(chatId));
  },

  pushPendingMessages: (message) => {
    context.push('pendingMessages', message);
  },

  storeEmail: (partnerId, email) => {
    context.set({ email });
  },

  storeCategory: (partnerId, category) => {
    context.set({ category });
  },

  storeCategoryParameter: (categoryParameter) => {
    context.set({ categoryParameter });
  },

  storeLocaleParameter: (localeParameter) => {
    context.set({ localeParameter });
  },

  storeCategorySelected: (partnerId, categorySelected) => {
    context.set({ categorySelected });
  },

  storeChatPriority: (priority) => {
    context.set({ priority });
  },

  storeRetrieveChatPriority: () => (
    context.get('priority', null)
  ),

  storeChatId: (partnerId, chatId) => {
    localStorageHelper.setObject(partnerId, CHAT_DATA_FIELD_CHATID, chatId);
  },

  storeChannel: (partnerId, channel) => {
    localStorageHelper.setObject(partnerId, 'channel', channel);
  },

  storeVisitorRole: (partnerId, role) => (
    localStorageHelper.setObject(partnerId, 'visitorRole', role)
  ),

  storeRetrieveVisitorRole: (partnerId) => (
    localStorageHelper.getObject(partnerId, 'visitorRole')
  ),

  storeChatSteps: (chatSteps) => (
    context.set({ chatSteps })
  ),

  storeRetrieveChatSteps: () => (
    context.get('chatSteps', [])
  ),

  isUser: (partnerId) => {
    const visitorRole = API.storeRetrieveVisitorRole(partnerId);
    const params = API.storeRetrieveParams();
    return (
      (params.isUser && !(visitorRole === 'lead')) || visitorRole === 'user'
    );
  },

  isLead: (partnerId) => {
    const visitorRole = API.storeRetrieveVisitorRole(partnerId);
    const params = API.storeRetrieveParams();
    return (
      (params.isLead && !(visitorRole === 'user')) || visitorRole === 'lead'
    );
  },

  storeReset: (partnerId) => {
    API.storeChatId(partnerId, null);
    setCurrentChatId(null);
    setFeedbackSubmitted(false);
    API.storeQuestion(partnerId, null);
    API.storeName(partnerId, null);
    API.storeEmail(partnerId, null);
    API.storeCustomMeta(partnerId, null);
    API.storeCategory(partnerId, null);
    API.storeCategorySelected(partnerId, null);
    API.storeUserPredictionAnswer(partnerId, null);
    API.storeChatPriority(null);
    localStorageHelper.removeObject(partnerId, 'contactConfirmed');
    localStorageHelper.removeObject(partnerId, 'automationBehavior');
    localStorageHelper.removeObject(partnerId, 'botFinished');
    localStorageHelper.removeObject(partnerId, 'preRatingDismissed');
  },

  storeResetPendingMessages: () => {
    context.set({ pendingMessages: [] });
  },

  getChatSettings: async (
    partnerId,
    locale = API.storeRetrieveLocaleParameter() || undefined,
    category = API.storeRetrieveCategoryParameter() || undefined,
    token = API.storeRetrieveToken() || undefined,
  ) => {
    if (!partnerId) {
      return {};
    }
    const { data: { chatSettings } } = await client.query({
      query: CHAT_SETTINGS,
      variables: {
        id: partnerId,
        locale,
        category,
        token,
      },
    });
    return chatSettings;
  },

  /** Performs chat initialization from scratch */
  initChat: (partnerId, pendingMessages) => {
    context.set({ pendingMessages });
    return pendingMessages;
  },

  /** Adds a new category */
  addCategory: (partnerId, category, categoryLabel) => {
    const message = API.messages.createAnonymousTextMessage(
      categoryLabel,
      true,
      false,
    );
    API.storeCategory(partnerId, category);
    API.storeCategorySelected(partnerId, true);
    context.push('pendingMessages', message);
    return message;
  },

  /** Adds a new message from a bot */
  addBotMessage: (botMessageCode, delay) => (
    sleep(delay ? API.getBotEllipsisMessageDelay() : 0).then(() => {
      const message = API.messages.craeteBotMessage(null, botMessageCode);
      context.push('pendingMessages', message);
      return message;
    })
  ),

  addBannedMessage: (chatId, isUser, posting) => {
    if (!isUser) {
      return;
    }
    const { chat } = client.readQuery({
      query: GET_CHAT,
      variables: {
        id: chatId,
        isVisibleByExpert: !isUser,
        isVisibleByUser: isUser,
      },
    });
    const message = visitorMessage(Date.now(), posting.text);
    client.writeQuery({
      query: GET_CHAT,
      variables: {
        id: chatId,
        isVisibleByExpert: !isUser,
        isVisibleByUser: isUser,
      },
      data: {
        chat: {
          ...chat,
          messages: {
            edges: chat.messages.edges.concat({
              ...message,
              __typename: 'MessageEdge',
            }),
          },
        },
      },
    });
  },

  addPosting: async (id, uid, chatId, isUser, isBot, posting) => {
    try {
      await client.mutate({
        mutation: SEND_CHAT_MESSAGE,
        variables: {
          input: {
            id: id || uuidv4(),
            userId: uid,
            chatId,
            isUser,
            isBot,
            posting,
          },
        },
      });
    } catch (e) {
      if (errorCode(e) === errorType.FORBIDDEN) {
        API.addBannedMessage(chatId, isUser, posting);
      } else {
        throw e;
      }
    }
  },

  addMessage: (
    id,
    uid,
    chatId,
    isUser,
    isBot,
    message,
    hideForUser = null,
    hideForExpert = null,
    type = MESSAGES.message,
    data = {},
    timestamp,
  ) => (
    API.addPosting(id, uid, chatId, isUser, isBot, {
      text: message,
      author: uid,
      isUser,
      isBot,
      type,
      hideForUser,
      hideForExpert,
      data,
      timestamp,
    })
  ),

  isAutomatedChatWithoutFallback: (partnerId) => {
    const automationBehavior = localStorageHelper.getObject(partnerId, 'automationBehavior');
    return automationBehavior?.allowFallback === false;
  },

  getMessageStyle: (partnerId) => (
    API.storeRetrieveChannel(partnerId) === 'form' ? 'smartForm' : undefined
  ),

  initStorageForNewRequest: (partnerId, chatId, options) => {
    if (chatId) {
      API.storeChatId(partnerId, chatId);
    }

    API.storeChannel(partnerId, options?.channel || 'chat');
    API.storeUserPredictionAnswer(partnerId, null);

    const params = API.storeRetrieveParams();
    if (params.customMeta) {
      try {
        const customMeta = JSON.parse(params.customMeta);
        API.storeCustomMeta(partnerId, customMeta);
      } catch (exception) {
        // Ignore input
      }
    }
    const { payloadName, payloadEmail, payloadQuestion } = params;
    const question = payloadQuestion || options?.payloadQuestion;
    API.storeName(partnerId, payloadName);
    API.storeEmail(partnerId, payloadEmail);
    API.storeQuestion(partnerId, question);
  },

  getChatURL: (partnerId, channel, { chatId, token, qs = '' } = {}) => {
    const format = channel === 'form' ? 'form' : 'chats';
    let url = `/${partnerId}/${format}`;
    if (chatId) {
      url = `${url}/${chatId}/${token}`;
    }
    return `${url}${qs}`;
  },
};

/* Expose API interface */
export default API;
