import {
  EyeOutlined,
  FacebookOutlined,
  FileTextOutlined,
  RobotOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { LocaleHelper } from '@guuru/helpers-common';
import { GuuruOutlined } from '@guuru/react-icon';

interface Filters {
  partners?: {
    node: {
      id: string,
      name: {
        en: string,
      },
    },
  }[];
  partnerCategories?: {
    categoryId: string,
    name: Record<string, string>,
  }[];
}

const PartnerFilters = {
  active: {
    key: 'active',
  },
  demo: {
    key: 'demo',
  },
  churned: {
    key: 'churned',
  },
  all: {
    key: 'all',
  },
  agentsEnabled: {
    key: 'agentsEnabled',
  },
  chatWidgetTrackLeads: {
    key: 'chatWidgetTrackLeads',
  },
  chatLoaderWorkingHoursEnabled: {
    key: 'chatLoaderWorkingHoursEnabled',
  },
  searchText: {
    key: 'searchText',
  },
  automationEnabled: {
    key: 'automationEnabled',
  },
  facebookEnabled: {
    key: 'facebookEnabled',
  },
  status: {
    key: 'status',
    label: 'Status',
    filters: [
      {
        key: 'all',
        name: 'All',
        icon: EyeOutlined,
      },
      {
        key: 'active',
        name: 'Active',
        icon: EyeOutlined,
      },
      {
        key: 'demo',
        name: 'Demo',
        icon: EyeOutlined,
      },
      {
        key: 'churned',
        name: 'Churned',
        icon: EyeOutlined,
      },
    ],
  },
  features: {
    key: 'features',
    label: 'Features Enabled',
    filters: [
      {
        key: 'automationEnabled',
        name: 'Automation',
        icon: RobotOutlined,
      },
      {
        key: 'facebookEnabled',
        name: 'Facebook',
        icon: FacebookOutlined,
      },
      {
        key: 'smartFormEnabled',
        name: 'SmartForm',
        icon: FileTextOutlined,
      },
    ],
  },
  settings: {
    key: 'settings',
    label: 'Settings Enabled',
    filters: [
      {
        key: 'chatWidgetTrackLeads',
        name: 'Track leads',
        icon: SettingOutlined,
      },
      {
        key: 'chatLoaderWorkingHoursEnabled',
        name: 'Working hours',
        icon: SettingOutlined,
      },
    ],
  },
  industry: {
    key: 'industry',
    label: 'Industry',
  },
  partner: {
    key: 'partner',
    label: 'Partner',
    type: 'partner',
    getFilters: ({ partners }: Filters) => (
      partners?.map(({ node }) => ({
        name: node.name.en,
        value: node.id,
      }))
    ),
  },
  category: {
    key: 'category',
    label: 'Category',
    getFilters: ({ partnerCategories }: Filters) => (
      partnerCategories?.map(({ categoryId, name }) => ({
        name: LocaleHelper.localize(name),
        value: categoryId,
      }))
    ),
  },
  community: {
    key: 'community',
    label: 'Community Features Enabled',
    filters: [
      {
        key: 'content',
        name: 'Content',
        icon: GuuruOutlined,
      },
      {
        key: 'communityEmbed',
        name: 'Community Embed',
        icon: GuuruOutlined,
      },
      {
        key: 'minimalEmbed',
        name: 'Minimal Embed',
        icon: GuuruOutlined,
      },
      {
        key: 'chatCommunitySpace',
        name: 'Chat Community Space',
        icon: GuuruOutlined,
      },
    ],
  },
};

// eslint-disable-next-line import/prefer-default-export
export { PartnerFilters };
