import { useReactiveVar } from '@apollo/client';
import { ChatAPI } from '@guuru/api-web';
import { CHANNELS } from '@guuru/chat-common';
import { ChatStep } from '@guuru/constants-common';
import storage from '../../storage/containers';

const {
  chatId,
  chatStep,
  chatChannel,
  isRunningStep,
  connectionError,
  step,
  inputType,
} = storage;

export default () => {
  const currentChatId = useReactiveVar(chatId);
  const currentChatStep = useReactiveVar(chatStep);
  const currentChatChannel = useReactiveVar(chatChannel);
  const currentIsRunningStep = useReactiveVar(isRunningStep);
  const currentConnectionError = useReactiveVar(connectionError);
  const currentStep = useReactiveVar(step);
  const currentInputType = useReactiveVar(inputType);
  const chatSteps = ChatAPI.storeRetrieveChatSteps();
  const hasCurrentChat = (
    chatSteps.indexOf(currentChatStep) === -1
    || chatSteps.indexOf(currentChatStep) > chatSteps.indexOf(ChatStep.chatLoad)
  );
  const isStepBeforeQuestion = (
    currentChatStep === null
    || [
      ChatStep.chatQuestion,
      ChatStep.chatInit,
      ChatStep.showWelcomeMessage,
      ChatStep.quickActionSelection,
    ].includes(currentChatStep)
  );
  return {
    currentChatId,
    currentChatStep,
    currentChatChannel,
    step: currentStep,
    inputType: currentInputType,
    isRunningStep: currentIsRunningStep,
    isCommunity: currentChatChannel === CHANNELS.community.id,
    isConnectionError: currentConnectionError,
    hasCurrentChat,
    isStepBeforeQuestion,
  };
};
