import { initGraphQLTada } from 'gql.tada';
// @ts-ignore generated code
import type { introspection } from './graphql-env.d';

export const gql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    JSON: any;
    URL: string;
    NPS: number;
    Timestamp: number;
    TimestampRange: number[];
    Date: string;
    DateRange: string[];
  },
}>();

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { readFragment } from 'gql.tada';
