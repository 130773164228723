import {
  createMongoAbility,
  MongoQuery,
  RawRuleFrom,
  Subject,
} from '@casl/ability';
import {
  allRoles,
  baseRoles,
  globalRoles,
  membersRoles,
  noPartnerRoles,
  permissionsRoles,
  possibleRoles,
  rolesDescriptions,
} from './src/roles';

type PossibleAbilities = [string, Subject];
type Conditions = MongoQuery;
interface PartnerAbilities {
  [Key: string]: RawRuleFrom<PossibleAbilities, Conditions>[];
}

const userRules = (
  partnerId: string,
  globalAbilities: RawRuleFrom<PossibleAbilities, Conditions>[] = [],
  partnerAbilities: PartnerAbilities = {},
): RawRuleFrom<PossibleAbilities, Conditions>[] => {
  const rules = [];

  if (globalAbilities.length) {
    rules.push(...globalAbilities);
  }

  if (partnerId && partnerAbilities[partnerId]) {
    rules.push(...partnerAbilities[partnerId]);
  }

  return rules;
};

export default {
  forUser(
    globalAbilities: RawRuleFrom<PossibleAbilities, Conditions>[],
    partnerAbilities: PartnerAbilities,
    partnerId: string,
  ) {
    return createMongoAbility<PossibleAbilities, Conditions>(
      userRules(partnerId, globalAbilities, partnerAbilities),
    );
  },

  forRole(abilities:RawRuleFrom<PossibleAbilities, Conditions>[]) {
    return createMongoAbility(abilities);
  },
};

export {
  possibleRoles,
  membersRoles,
  allRoles,
  globalRoles,
  permissionsRoles,
  baseRoles,
  noPartnerRoles,
  rolesDescriptions,
};
