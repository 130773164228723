import React from 'react';
import styled, { css } from 'styled-components';
import { Avatar } from 'antd';

import type { AvatarProps } from 'antd';

const small = css`
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-top: -3px;
`;

interface SizedAvatarProps extends AvatarProps {
  $size?: string;
  // eslint-disable-next-line no-unused-vars
  onError?: (e?: React.ChangeEvent<HTMLImageElement>) => boolean;
}

const SizedAvatar = styled(Avatar)<SizedAvatarProps>`
  ${({ $size }) => ($size === 'small' ? small : null)}
`;

export default SizedAvatar;
export type { SizedAvatarProps };
