import log from '@guuru/logger-web';
import { ChatAPI } from '@guuru/api-web';
import { ChatState, ChatStep } from '@guuru/constants-common';
import { getCurrentChatStep, updateChatStep } from '@guuru/chat-web';
import { BOTS } from '@guuru/expert-common';
import updateChatConnectionError from './updateChatConnectionError';

const findCurrentStep = function ({ status, messages, expert }) {
  switch (status) {
    case ChatState.created:
    case ChatState.assigned:
      return ChatStep.connectingExpert;
    case ChatState.open: {
      if (expert?.id !== BOTS.bot.externalId) {
        return ChatStep.chat;
      }

      const lastMessage = messages.edges[messages.edges.length - 1];
      return (lastMessage?.node?.type === 'intentSelection'
        ? ChatStep.requestIntentSelection
        : ChatStep.makePrediction);
    }
    case ChatState.closed:
      return ChatStep.closeChat;
    case ChatState.rated:
      return ChatStep.rateChat;
    case ChatState.handover:
    case ChatState.missed:
      return ChatStep.chatEnd;
    default:
      return null;
  }
};

const shouldUpdateChatStep = (step) => {
  if (!step) return false;

  const currentStep = getCurrentChatStep();
  const chatSteps = ChatAPI.storeRetrieveChatSteps();
  if (currentStep === ChatStep.rateBadChat && step === ChatStep.closeChat) {
    return false;
  }
  return chatSteps.indexOf(step) === -1
    || chatSteps.indexOf(step) > chatSteps.indexOf(currentStep);
};

export default function ({ chatId, ...updatedChat }) {
  try {
    updateChatConnectionError(false);
    const step = findCurrentStep(updatedChat);
    if (shouldUpdateChatStep(step)) {
      updateChatStep(step);
    }
  } catch (error) {
    log.error(error);
  }
}
