import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { colors } from '@guuru/constants-common';
import VerticalLayout from './VerticalLayout';
import HorizontalLayout from './HorizontalLayout';

interface HighlightCardProps {
  url: string;
  title: string;
  description: string;
  name?: string;
  subTitle?: string;
  layout?: 'vertical' | 'horizontal';
  avatar?: string;
  footer?: React.ReactNode;
  extraTitle?: React.ReactNode;
  isLoading?: boolean;
  tooltip?: string;
  actions?: (string | React.ReactNode)[]
}

const HighlightCard = function ({
  url,
  title,
  description,
  isLoading = false,
  avatar = undefined,
  name = undefined,
  subTitle = undefined,
  footer = null,
  extraTitle = null,
  actions = [],
  tooltip = '',
  layout = 'vertical',
}: HighlightCardProps) {
  return (
    <Link to={url}>
      <Tooltip
        title={tooltip}
        color={colors.primaryColor}
        placement="top"
        arrow={false}
        align={{ offset: [0, 20] }}
        overlayStyle={{ cursor: 'pointer' }}
        autoAdjustOverflow={false}
        zIndex={1}
      >
        <span>
          {layout === 'vertical'
            ? (
              <VerticalLayout
                loading={isLoading || (!isLoading && !title)}
                title={title}
                avatar={avatar}
                subTitle={subTitle}
                description={description}
                extraTitle={extraTitle}
                footer={footer}
                actions={actions}
                name={name}
              />
            )
            : (
              <HorizontalLayout
                loading={isLoading || (!isLoading && !title)}
                description={description}
                title={title}
                avatar={avatar}
                name={name}
              />
            )}
        </span>
      </Tooltip>
    </Link>
  );
};

export default HighlightCard;
