import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import SendButtonSvg from './SendButtonOutlined.svg';

const SendButtonOutlined = function (props: Parameters<typeof Icon>) {
  return (
    <Icon component={SendButtonSvg} {...props} />
  );
};

export default SendButtonOutlined;
