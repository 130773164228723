import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import SalesforceSvg from './SalesforceOutlined.svg';

type IconProps = React.ComponentProps<typeof Icon>;

const SalesforceOutlined = function (...props: IconProps[]) {
  return <Icon component={SalesforceSvg} {...props} />;
};

export default SalesforceOutlined;
