import { toCents } from './amount';

const parseMoney = (value: string, { isCents = false } = {}) => {
  let amount = NaN;

  const dotSeparatedValue = value.trim().replaceAll(/[\s,]/g, '.');
  const indexOfLastSeparator = dotSeparatedValue.lastIndexOf('.');
  const suffix = dotSeparatedValue.substring(indexOfLastSeparator);

  if (suffix.length > 3) {
    amount = Number(dotSeparatedValue.replaceAll('.', ''));
  } else {
    const prefix = dotSeparatedValue
      .substring(0, indexOfLastSeparator)
      .replaceAll('.', '');
    amount = Number(`${prefix}${suffix}`);
  }

  return isCents ? amount : toCents(amount);
};

// eslint-disable-next-line import/prefer-default-export
export { parseMoney };
