import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

type Props = {
  partnerId: string,
  path: string,
  text: string,
};

const Link = styled(Typography.Link)`
  && {
    color: ${({ theme }) => theme.guuruColor};
  }
`;

const PageLink = function ({ partnerId, path, text }: Props) {
  return (
    <Link
      target="_blank"
      href={`${process.env.PAGES_URL}/${partnerId}/articles/${path}`}
      style={{ fontSize: 'inherit' }}
    >
      {text}
    </Link>
  );
};

export default PageLink;
