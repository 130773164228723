import styled from 'styled-components';
import { colors } from '@guuru/constants-common';

const linkColor = (theme, surfaceColor) => (
  theme.primaryColor !== surfaceColor
    ? theme.primaryVariants?.[6]
    : theme.onSecondaryColor
);

const profileImageWidthLocal = '36px';

export default styled.div`
  display: flex;
  margin: 5px 0;
  overflow-wrap: break-word;
  align-items: center;

  @supports (-ms-ime-align:auto) {
    word-wrap: break-word;
  }

  .chat-message {
    display: flex;
    flex-wrap: nowrap;
  }

  .chat-message-text-section > div > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .chat-message-text-message {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.3rem;
  }

  &:hover {
    .anticon-delete {
      visibility: initial;
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.skip-profile-image {
    margin-bottom: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .chat-message.mod--fullBubble.chat-message.mod--borderless {
    border: 0;
    padding: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .chat-message.mod--fullBubble.chat-message.mod--transparent {
    background: transparent;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .chat-message.mod--fullBubble {
    padding: 8px 12px;
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.shadow};
    border-radius: ${({ theme }) => theme.mediumRadius};
  }

  a {
    text-decoration: underline;
    word-break: break-word;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--center {
    justify-content: ${({ chatAlignment }) => chatAlignment ?? 'center'};
    width: 100%;
    display: flex;

    &.without-profile-image {
      margin-right: ${profileImageWidthLocal};
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--left {
    flex-direction: row;
    margin-right: 15%;

    &.without-profile-image {
      margin-left: ${profileImageWidthLocal};
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .chat-message.mod--fullBubble,
    .bubble-background {
      background-color: ${({ theme }) => theme.surfaceColor};
      border-color: ${({ theme }) => theme.surfaceColor};
      border-bottom-left-radius: 0;
    }

    &.skip-profile-image {
      .chat-message.mod--fullBubble {
        border-bottom-left-radius: ${({ theme }) => theme.mediumRadius};
      }
    }

    .chat-message-text-message {
      color: ${({ theme }) => theme.onSurfaceColor};

      a {
        color: ${({ theme }) => linkColor(theme, theme.surfaceColor)};
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--right {
    flex-direction: row-reverse;
    margin-left: 25%;

    &.without-profile-image {
      margin-right: ${profileImageWidthLocal};
    }

    &.smart-form {
      margin-left: 0;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .chat-message.mod--fullBubble,
    .bubble-background {
      background-color: ${({ theme }) => theme.secondaryColor};
      border-color: ${({ theme }) => theme.secondaryColor};
      border-bottom-right-radius: 0;
    }

    &.skip-profile-image {
      .chat-message.mod--fullBubble {
        border-bottom-right-radius: ${({ theme }) => theme.mediumRadius};
      }
    }

    .chat-message-text-message {
      color: ${({ theme }) => theme.onSecondaryColor};

      a {
        color: ${({ theme }) => linkColor(theme, theme.secondaryColor)};
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--fullWidth {
    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .chat-message.mod--fullBubble.mod--smartForm {
    overflow: visible;
    position: relative;
    width: 100%;
    box-shadow: rgba(0 0 0 / 20%) 0 0 8px -2px;
    padding: 16px;
    margin: 25px 0;
    border: 1px solid ${({ theme }) => theme.neutralVariants[2]};
    border-radius: ${({ theme }) => theme.smallRadius};
    background-color: ${({ theme }) => theme.backgroundColor};

    .chat-message-text-message {
      font-size: 16px;
      font-weight: 200;
    }

    .ant-form {
      max-width: initial;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -39px;
      left: 46px;
      border: 15px solid transparent;
      border-bottom-color: ${({ theme }) => theme.neutralVariants[2]};
      border-bottom-width: 24px;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .chat-message.mod--fullBubble.mod--smartForm::after {
    left: 47px;
    border: 14px solid transparent;
    border-bottom-color: ${({ theme }) => theme.backgroundColor};
    top: -36px;
    border-bottom-width: 23px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .mod--expert & {
    &.bot {
      /* stylelint-disable-next-line selector-class-pattern */
      .chat-message.mod--fullBubble {
        background-color: ${colors.darkBlue};
        border-color: ${colors.darkBlue};
      }
    }
  }

  /* Styling for picking a partner in an organization (Sky) */
  .message-partners-list-item {
    margin: 5px 0 8px;
    width: 100%;
    min-width: 180px;
  }

  .message-partners-list-item-anchor {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e0e0e0;
    padding: 6px 24px 6px 6px;
    margin: 5px 12px 8px 0;
    border-radius: 5px;
    width: 100%;
    background: white;
    min-width: 200px;
    cursor: pointer;
  }

  .message-partners-list-item-logo {
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }

  .message-partners-list-item-name {
    color: #2f2f2f;
    font-size: 18px;
    font-weight: 300;
  }

  .message-partners-list-item .message-partners-list-item-anchor {
    text-decoration: none;
  }

  .message-partners-list-item-anchor:hover {
    text-decoration: none;
    border: 1px solid #8a8a8a;
    background-color: #e1e1e1;
    color: black;
  }
`;
