const maxPercentage = 100;
const minChatsReviewed = 5;

const MONITORING = {
  minChatsReviewed,
  maxPercentage,
  minPercentage: maxPercentage / minChatsReviewed,
  minChatsDone: 5,
  progressBarWarningColor: '#ffa500',
  progressBarExceptionColor: '#ff8162',
  progressBarSuccessColor: '#52c41a',
  progressBarInprogressColor: '#2096f2',
  goodRatingPercentage: 0.8,
  badRatingPercentage: 0.75,
};

// eslint-disable-next-line import/prefer-default-export
export { MONITORING };
