const BOTS = {
  bot: {
    id: 0,
    externalId: 'bot',
    name: '',
    photoUrl: 'https://cdn.guuru.com/assets/logo/guuru_logo.svg',
  },
  freshchat: {
    id: -1,
    externalId: 'freshchat',
    ip: 'fd00:0210:2020:f0e0:0c0a:0234::25de',
  },
  salesforce: {
    id: -2,
    externalId: 'salesforce',
    ip: 'cf80:0830:e098:bb59:93a7:9a3b::7303',
    authenticationUrls: [
      'https://login.salesforce.com',
      'https://test.salesforce.com',
    ],
  },
  api: {
    id: -3,
  },
  copilot: {
    id: -4,
    externalId: 'gbot',
  },

  isBot(id) {
    return [
      BOTS.bot.externalId,
      BOTS.copilot.externalId,
      BOTS.freshchat.externalId,
    ].includes(id);
  },
};

// eslint-disable-next-line import/prefer-default-export
export { BOTS };
