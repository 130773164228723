import styled from 'styled-components';
import { Table } from 'antd';

const LeaderboardTable = styled(Table)`
  --gold-rgba: rgba(255 223 133 / 20%);
  --silver-rgba: rgba(180 180 220 / 20%);
  --bronze-rgba: rgba(220 150 80 / 10%);
  --highlight-row-rgba: rgba(232 244 255 / 98%);
  --gray-row-bg-rgba: rgba(244 244 245 / 50%);
  --row-hover-bg-hex: #E6F7FF;
  --transparent-rbga: rgba(255 255 255 / 0%);

  width: 100%;
  box-shadow: 0 -2px 4px rgba(0 0 0 / 5%), 0 2px 4px rgba(0 0 0 / 8%);

  .ant-table-tbody {
    tr {
      cursor: pointer;
    }

    tr:nth-child(2n+1) td {
      background-color: var(--gray-row-bg-rgba);;
    }

    tr:nth-child(1 of .ant-table-row) td:first-child {
      background-image: linear-gradient(to right, var(--gold-rgba) 0%, var(--transparent-rbga) 100%);
    }

    tr:nth-child(2 of .ant-table-row) td:first-child {
      background-image: linear-gradient(to right, var(--silver-rgba) 0%, var(--transparent-rbga) 100%);
    }

    tr:nth-child(3 of .ant-table-row) td:first-child {
      background-image: linear-gradient(to right, var(--bronze-rgba) 0%, var(--transparent-rbga) 100%);
    }

    .highlight-guuru-row {
      position: sticky;
      bottom: 0;
      background-color: var(--highlight-row-rgba);
      z-index: 99;
    }

    .ant-table-row-expand-icon-cell {
      padding: 0;
    }
  }
`;

export default LeaderboardTable;
