import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flow as flowConstants, getFeedbackSubmitted } from '@guuru/chat-web';
import { Loading } from '@guuru/react-loading';
import { useReactiveVar } from '@apollo/client';
import { BOTS } from '@guuru/expert-common';
import ChatThanks from './ChatThanks';
import BottomBar from '../BottomBar';
import ChatRequestFeedback from './ChatRequestFeedback';

const ChatThankYouSection = function ({
  isUser,
  flowStep = null,
  chatId = null,
  expert = {},
  doBefore = () => {},
}) {
  const isFeedbackSubmitted = useReactiveVar(getFeedbackSubmitted());
  const isExpert = ![BOTS.bot.externalId].includes(expert?.id);
  const isEnabled = isUser && chatId
    && flowStep === flowConstants.STEP_FINISHED
    && expert?.id !== undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (isEnabled) { doBefore(); } }, [isEnabled]);

  if (!isEnabled) {
    return null;
  }

  return (
    <BottomBar isUser={isUser}>
      <Suspense fallback={<Loading />}>
        {(!isFeedbackSubmitted && isExpert)
          ? <ChatRequestFeedback chatId={chatId} />
          : <ChatThanks chatId={chatId} />}
      </Suspense>
    </BottomBar>
  );
};

ChatThankYouSection.propTypes = {
  isUser: PropTypes.bool.isRequired,
  flowStep: PropTypes.string,
  chatId: PropTypes.string,
  expert: PropTypes.shape({
    id: PropTypes.string,
  }),
  doBefore: PropTypes.func,
};

export default ChatThankYouSection;
