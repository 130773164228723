import React from 'react';
import {
  ApiOutlined,
  CheckCircleOutlined,
  DisconnectOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { localesData } from '@guuru/constants-common';

interface Filters {
  subscribedIntents?: string[];
  partner?: {
    categories: {
      name: string,
      id: string,
    }[]
  };
  partners?: {
    node: {
      name: {
        en: string,
      }
      id: string,
    }
  }[];
}

type Filter = {
  key: string,
  name: string,
  icon: React.FC,
  value?: string[],
};

const confirmed: Filter = {
  key: 'confirmed',
  name: 'Confirmed',
  icon: CheckCircleOutlined,
};

const pending: Filter = {
  key: 'pending',
  name: 'Pending',
  icon: QuestionCircleOutlined,
};

const notSet: Filter = {
  key: 'notSet',
  name: 'New',
  icon: MinusCircleOutlined,
};

const subscribed = {
  key: 'subscribed',
  name: 'Subscribed',
  icon: ApiOutlined,
};

const unsubscribed = {
  key: 'unsubscribed',
  name: 'Unsubscribed',
  icon: DisconnectOutlined,
};

const QuestionFilters = {
  intentId: {
    key: 'intentId',
  },
  language: {
    key: 'language',
    label: 'Language',
    type: 'language',
    filters: Object.entries(localesData).map(([key, { name }]) => (
      { name, value: key }
    )),
  },
  searchText: {
    key: 'searchText',
  },
  industry: {
    key: 'industry',
    label: 'Industry',
  },
  partner: {
    key: 'partner',
    label: 'Partner',
    type: 'partner',
    getFilters: ({ partners }: Filters) => (
      partners?.map(({ node }) => ({ name: node.name.en, value: node.id }))
    ),
  },
  notSet,
  pending,
  confirmed,
  subscribed,
  unsubscribed,
  filterBy: {
    key: 'filterBy',
    label: 'Show Only',
    getFilters: ({ subscribedIntents }: Filters) => {
      const baseFilters = [notSet, pending, confirmed];
      if (subscribedIntents?.length) {
        baseFilters.push({ ...subscribed, value: subscribedIntents });
        baseFilters.push({ ...unsubscribed, value: subscribedIntents });
      }
      return baseFilters;
    },
  },
};

// eslint-disable-next-line import/prefer-default-export
export { QuestionFilters };
