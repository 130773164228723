import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import FreshchatSvg from './FreshchatOutlined.svg';

const FreshchatOutlined = function (props: Parameters<typeof Icon>) {
  return (
    <Icon component={FreshchatSvg} {...props} />
  );
};

export default FreshchatOutlined;
