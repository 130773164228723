const gutter = {
  sm: 8,
  md: 24,
  lg: 16,
  xl: 24,
};

const colors = {
  white: '#fff',
  primaryColor: '#2096f2',
  green: '#59a14f',
  blue: '#00B0FF',
  darkBlue: '#003056',
  red: '#e15759',
  black: '#000',
  yellow: '#f8ac59',
  orange: '#f28f2c',
  purple: '#7da6ff',
  peach: '#f797d6',
  gray: '#e5e5e5',
  borderBase: '#e5e5e5',
  borderSplit: '#f4f4f4',
  grass: '#d6fbb5',
  sky: '#c1e0fc',
  purpleDarker: '#ae59d4',
  backgroundOptionDisabled: '#f5f5f5',
  textOptionDisabled: 'rgba(0,0,0, 0.25)',
};

export { colors, gutter };
