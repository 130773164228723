import React, { JSX } from 'react';
import ReactLinkify from 'react-linkify';
import LinkifyIt from 'linkify-it';
import { phoneNumberValidator } from './phoneNumber';
import hyperlinkValidator from './hyperlinkValidator';

const MAX_LENGTH = 35;
const SUFFIX_LENGTH = 10;

const componentDecorator = (href: string, text: string, key: React.Key) => {
  let linkText = text;
  let cleanText = text;
  let validHref = href;

  const regResult = /\[([\w\W]+)]\s*\(([\w\\/.,%&#?=:-]+)\)/.exec(text);
  if (regResult) {
    cleanText = regResult?.[1];
    validHref = regResult?.[2].includes('http')
      ? regResult[2]
      : 'http://'.concat(regResult[2]);
  }

  if (text.length > MAX_LENGTH) {
    const prefixLength = MAX_LENGTH - SUFFIX_LENGTH - 3;
    const prefix = text.substring(0, prefixLength);
    const suffix = text.substring(text.length - SUFFIX_LENGTH, text.length);
    linkText = `${prefix}...${suffix}`;
  }

  return (
    <a href={(validHref) || href} key={key} target="_blank" rel="noopener noreferrer">
      {cleanText || linkText}
    </a>
  );
};

const matchDecorator = (textToMatch: string) => {
  const linkify = new LinkifyIt();
  linkify.add('[', {
    validate: hyperlinkValidator,
  });

  // Joao made this hack because this library is stupid
  ['+', '(', ...Array(10).keys()]
    .forEach((prefix) => linkify.add(prefix as string, {
      validate: phoneNumberValidator,
      normalize(match: { url: string; }) {
        // eslint-disable-next-line no-param-reassign
        match.url = `tel:${match.url.replace(/[\s-)(]/g, '')}`;
      },
    }));

  return linkify.match(textToMatch);
};

interface LinkifyProps {
  text?: string | JSX.Element,
  supportMarkdown?: boolean,
}

const Linkify = function ({
  text = undefined,
  supportMarkdown = true,
}: LinkifyProps) {
  return (
    // @ts-ignore ReactLinkify still uses types from React 16.x
    <ReactLinkify
      componentDecorator={supportMarkdown ? componentDecorator : undefined}
      matchDecorator={supportMarkdown ? matchDecorator : undefined}
    >
      {text}
    </ReactLinkify>
  );
};

export default Linkify;
