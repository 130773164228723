import { useEffect } from 'react';

const usePageTitle = function (pageTitle: string) {
  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
  }, [pageTitle]);
};

export default usePageTitle;
