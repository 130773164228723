const regResult = /\[([\w\W]+)]\s*\(([\w\\/.,%&#?=:-]+)\)/;

export default (text: string, pos = 1) => {
  const tail = text.slice(pos - 1);

  if (!regResult.test(tail)) return 0;
  const result = tail.match(regResult);

  if (!result) return 0;
  const [match] = result;

  return match.length - 1 || false;
};
